<template>
    <div>
        <GlobSpinner :isLoading="isLoading" />
		 <GlobTabs :initialTab="'general'" :tabs="tabs" :keepState="false">
			<!-- General section -->
			<template v-slot:tab-panel-general>
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.name')}}*
								<InputDynamic :fullWidth="true" :type="'text'"
									:placeholder="$t('administration.asset.fields.name')" v-model="asset.form.Name" />
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.description')}}
								<InputDynamic :fullWidth="true" :type="'text'"
									:placeholder="$t('administration.asset.fields.description')"
									v-model="asset.form.Description" />
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<div style="position: absolute;">
								<GlobQrCode :name="asset.form.Name" :content="qrCodeContent" :downloadFileName="'Asset-'+asset.form.Name" @enlargeQRCodeActive="(val) => enlargeQRCodeActive(val)"/>
							</div>
						</GlobCol>
						<GlobCol :medium="6" v-if="assetCategories.length">
							<label>
								{{!showAssetCategoryAddForm ? $t('administration.asset.fields.asset_category') : $t('administration.asset.create_category')}}
								<span class="add-extra-item" @click="showAssetCategoryAddForm = !showAssetCategoryAddForm"><i
										class="fas fa-plus-circle"></i></span>
								<template v-if="!showAssetCategoryAddForm">
									<InputDynamic :fullWidth="true" :type="'multiselect'"
										:placeholder="$t('administration.asset.fields.asset_category')"
										v-model="asset.form.AssetCategories" :data="assetCategories"
										:selected="asset.form.AssetCategories" />
								</template>
								<template v-else>
									<InputDynamic :fullWidth="true" :type="'text'"
										:placeholder="$t('administration.asset.fields.asset_category')"
										v-model="newCategoryName" />
									<p class="text-danger" v-if="newCategoryNameError">{{newCategoryNameError}}</p>

									<button @click="addNewCategory()" class="button button--small mas-mt-2"
										:disabled="!newCategoryName">{{$t('general.button.save')}}</button>
									<button @click="showAssetCategoryAddForm = false"
										class="button button--small button--orange">{{$t('general.button.cancel')}}</button>

								</template>
							</label>
						</GlobCol>
					</GlobRow>
					<GlobRow>
						<GlobCol :medium="6" v-if="assetGroups.length">
							<label>
								{{!showAssetGroupAddForm ? $t('administration.asset.fields.asset_groups') : $t('administration.asset.create_group')}}
								<span class="add-extra-item" @click="showAssetGroupAddForm = !showAssetGroupAddForm"><i
										class="fas fa-plus-circle"></i></span>
								<template v-if="!showAssetGroupAddForm">
									<InputDynamic :fullWidth="true" :type="'virtualselect'"
										:placeholder="$t('administration.asset.fields.asset_groups')"
										v-model="asset.form.AssetGroup" :data="assetGroups" :selected="asset.form.AssetGroup" />
								</template>
								<template v-else>
									<InputDynamic :fullWidth="true" :type="'text'"
										:placeholder="$t('administration.asset.fields.asset_category')"
										v-model="newGroupName" />
									<p class="text-danger" v-if="newGroupNameError">{{newGroupNameError}}</p>

									<button @click="addNewGroup()" class="button button--small mas-mt-2"
										:disabled="!newGroupName">{{$t('general.button.save')}}</button>
									<button @click="showAssetGroupAddForm = false"
										class="button button--small button--orange">{{$t('general.button.cancel')}}</button>
								</template>

							</label>
						</GlobCol>
					</GlobRow>

					<GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.matching_field')}}
								<InputDynamic
								:fullWidth="true"
								:type="'text'"
								:placeholder="$t('administration.asset.fields.matching_field')"
								v-model="asset.form.MatchingField"
								/>
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.parent')}}
								<InputDynamic :fullWidth="true" :type="'virtualselect'"
									:placeholder="$t('administration.asset.fields.parent')" v-model="asset.form.ParentId"
									:data="assetParents" />
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.manufacture')}}
								<InputDynamic :fullWidth="true" :type="'text'"
									:placeholder="$t('administration.asset.fields.manufacture')"
									v-model="asset.form.Manufacture" />
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.model')}}
								<InputDynamic :fullWidth="true" :type="'text'"
									:placeholder="$t('administration.asset.fields.model')" v-model="asset.form.Model" />
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.serial_number')}}
								<InputDynamic :fullWidth="true" :type="'text'"
									:placeholder="$t('administration.asset.fields.serial_number')"
									v-model="asset.form.SerialNumber" />
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.production_year')}}
								<InputDynamic :fullWidth="true" :type="'number'"
									:placeholder="$t('administration.asset.fields.production_year')"
									v-model="asset.form.ProductionYear" :preventDecimal="true" />
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.installation_year')}}
								<InputDynamic :fullWidth="true" :type="'number'"
									:placeholder="$t('administration.asset.fields.installation_year')"
									v-model="asset.form.InstallationYear" />
							</label>
						</GlobCol>
					</GlobRow>
					<p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
					<div class="mas-mt-4">
					<button @click="save" class="button"
						:disabled="!asset.form.Name">{{$t('general.button.save')}}</button>
					<button @click="$emit('close')"
						class="button button--orange">{{$t('general.button.cancel')}}</button>
					<button @click="$emit('showDeleteConfirmModal', true)"
						class="button button--red float-right">{{$t('general.button.delete')}}</button>
					</div>
				</GlobXpanel>
			</template>
			<!-- Attachments -->
            <template v-slot:tab-panel-attachment>
                <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" class="file-library-section">
                    <GlobRow>
                        <GlobCol :medium="12" class="select-upload-library-file" v-if="false">
							<div>
								<p>{{$t('administration.asset.select_from_library')}}</p><span><i class="fas fa-photo-video" @click="$emit('showFileLibraryModal', true)"></i></span>
							</div>
							<div>
								<p>{{$t('administration.asset.add_new_file')}}</p><span><i class="fas fa-file-upload blue" @click="$emit('showFileLibraryModal', 'add_new')"></i></span>
							</div>
						</GlobCol>
						<GlobCol :medium="12" class="select-upload-library-file">
							<GlobFileLibrary 
								@showFileLibraryModal=" (val) => $emit('showFileLibraryModal', val)"
								@inputChanged = "(item) => attachmentAddedOrRemoved(item)"
								:addedFiles="attachedFiles">
							   </GlobFileLibrary>
						</GlobCol>
					</GlobRow>
						<template  v-if="attachments && attachments.length">
						<div class="grid-container">
							<div class="grid-x add-file" v-sortable="{ disabled: false, sorting: attachments, callback: sortingCallback}">
								<GlobCol class="files-section " :medium="3" v-for="attachment in attachments" :key="'library-file-'+attachment.Id">
									<div class='parent'>
										<p>{{attachment.file_library.Name.length > 10 ? attachment.file_library.Name.slice(0, 10)+'...' :  attachment.file_library.Name}}</p>
										<template v-if="(['jpeg', 'jpg', 'png', 'svg', 'webp']).includes(attachment.file_library.Type)">
											<div class="thumb" :style="`background-image: url('${getSavedFileURLFromPath(attachment.file_library.Path)}')`" 
												@click="downloadFile(attachment.file_library.Path, attachment.file_library.Name+'.'+attachment.file_library.Type)"
												>
												<span :data-test="'background-image: url('+getSavedFileURLFromPath(attachment.file_library.Path)+')'" class="remove" @click.stop="removeFileFromAsset(attachment.FileLibraryId)">-</span>
											</div>	
										</template>
										<template v-else>
											<div class="thumb" :style="'background-image: url('+bgThumb+')'" 
												@click="downloadFile(attachment.file_library.Path, attachment.file_library.Name+'.'+attachment.file_library.Type)"
												>
												<span class="remove" @click.stop="removeFileFromAsset(attachment.FileLibraryId)">-</span>
											</div>	
										</template>				
									</div>
								</GlobCol>
							</div>
						</div>
						</template>
				</GlobXpanel>
			</template>
			<!-- Articles linked to assets -->
			<template v-slot:tab-panel-articles>
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.fields.select_article_to_add')}}*
								<InputDynamic :fullWidth="true" 
									:hideInput="true"
									:type="'virtualselect'"
									:placeholder="$t('administration.asset.fields.select_article_to_add')"
									v-model="articleToAdd"
									:data="articles" />
							</label>
						</GlobCol>
					</GlobRow>
					<GlobTable
						class="mas-mt-4"
						:maxHeight="50"
						:enableEditing="true"
						:fields="fieldsToEdit"
    					@change-item="(item) => updateAssetArticle(item)"
						:itemRenderFunctions="renderFunctions"
						:data="{
							head : assetArticlesHead,
							body : assetArticlesBody
						}"
						:actions="actions"
					/>
				</GlobXpanel>
			</template>
			<!-- Maintenance plans -->
			<template v-slot:tab-panel-plans>
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<div class="crud-expand">
						<p>{{$t('administration.asset.add_plan')}}</p>
						<span ><i class="fas fa-plus-circle" @click="showAddPlanForm = !showAddPlanForm"></i></span>
					</div>
					<template v-if="showAddPlanForm">
						<GlobRow>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.name')}}*
									<InputDynamic
									:fullWidth="true"
									:type="'text'"
									:placeholder="$t('administration.asset.plan.fields.name')"
									v-model="maintenancePlan.Name"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.period')}}*
									<InputDynamic
									:fullWidth="true"
									:type="'virtualselect'"
									:placeholder="$t('administration.asset.plan.fields.period')"
									v-model="maintenancePlan.PeriodicTypeId"
									:data="periodicTypes"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.frequency')}}*
									<InputDynamic
									:fullWidth="true"
									:type="'number'"
									:placeholder="$t('administration.asset.plan.fields.frequency')"
									v-model="maintenancePlan.Frequency"
									:preventDecimal="true"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<p class="maintenance-plan-explanation">{{ maintenancePlanExplanation}}</p>
							</GlobCol>
						</GlobRow>
						<GlobRow>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.start_date')}}*
									<InputDynamic
									:fullWidth="true"
									:type="'date'"
									:placeholder="$t('administration.asset.plan.fields.start_date')"
									v-model="maintenancePlan.StartDate"
									:disabled="maintenancePlanServiceLogExists"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.stop_date')}}*
									<InputDynamic
									:fullWidth="true"
									:type="'date'"
									:placeholder="$t('administration.asset.plan.fields.stop_date')"
									v-model="maintenancePlan.StopDate"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.first_service_date')}}*
									<InputDynamic
									:fullWidth="true"
									:type="'date'"
									:placeholder="$t('administration.asset.plan.fields.first_service_date')"
									v-model="maintenancePlan.FirstServiceDate"
									:min="maintenancePlanServiceLogExists ? maintenancePlan.FirstServiceDate : ''"
									/>
								</label>
							</GlobCol>
						</GlobRow>
						<GlobRow>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.latest_execution_time')}}
									<InputDynamic
									:fullWidth="true"
									:type="'time'"
									:placeholder="$t('administration.asset.plan.fields.latest_execution_time')"
									v-model="maintenancePlan.LatestExecutionTime"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.enable')}}
								</label>
								<div class="mas-mt-2">
									<InputCheckbox
										class="mas-mr-2"
										:name="'latest-execution-time-enabled'"
										v-model="maintenancePlan.LatestExecutionTimeEnabled"
										/>
								</div>
							</GlobCol>
						</GlobRow>
						<GlobRow>
							<template v-if="!addServiceDescription">
								<GlobCol :medium="3">
									<label>
										{{$t('administration.asset.plan.fields.service_description')}}*
										<span class="add-extra-item" @click="addServiceDescription = true"><i
														class="fas fa-plus-circle"></i></span>
										<InputDynamic
										:fullWidth="true"
										:type="'virtualselect'"
										:placeholder="$t('administration.asset.plan.fields.service_description')"
										v-model="maintenancePlan.MaintenanceServiceDescriptionId"
										:data="serviceDescriptions"
										/>
									</label>
								</GlobCol>
							</template>
							<template v-else>
								<GlobCol :medium="6">
								<label>
									{{$t('administration.asset.plan.create_service_description')}}*
								<InputDynamic 
											:fullWidth="true" 
											:type="'text'"
											:placeholder="$t('administration.asset.plan.fields.name')+'*'"
											v-model="serviceDescription.Name" />
								</label>
								<InputDynamic 
											:fullWidth="true" 
											:type="'textarea'"
											:rows="6"
											:placeholder="$t('administration.asset.plan.fields.description')+'*'"
											v-model="serviceDescription.Description" />
								<button @click="addNewServiceDescription()" class="button button--small mas-mt-2"
													:disabled="isServiceDescriptionSaveButtonDisabled">{{$t('general.button.save')}}</button>
								<button @click="addServiceDescription = false"
									class="button button--small button--orange">{{$t('general.button.cancel')}}</button>
								</GlobCol>
							</template>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.responsible_persons')}}*
									<InputDynamic
									:fullWidth="true"
									:type="'multiselect'"
									:placeholder="$t('administration.asset.plan.fields.responsible_persons')"
									v-model="maintenancePlan.ResponsiblePersons"
									:data="responsiblePersonsList"
									:selected="maintenancePlan.ResponsiblePersons"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.reminder_days')}}*
									<InputDynamic
									:fullWidth="true"
									:type="'number'"
									:placeholder="$t('administration.asset.plan.fields.reminder_days')"
									v-model="maintenancePlan.ReminderDays"
									:preventDecimal="true"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.alert_by')}}
								</label>
								<div class="mas-mt-2">
									<label class="mas-mr-3 d-inline-flex">
										<InputCheckbox
											class="mas-mr-2"
											:name="'alert-by-phone'"
											v-model="maintenancePlan.AlertByPhone"
											/>
											{{$t('administration.asset.plan.fields.alert_by_phone')}}
									</label>
									<label class="d-inline-flex">
										<InputCheckbox
											class="mas-mr-2"
											:name="'alert-by-email'"
											v-model="maintenancePlan.AlertByEmail"
											/>
											{{$t('administration.asset.plan.fields.alert_by_email')}}
									</label>
								</div>
							</GlobCol>
						</GlobRow>
						<GlobRow>			
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.supplier')}}
									<InputDynamic
									:fullWidth="true"
									:type="'multiselect'"
									:placeholder="$t('administration.asset.plan.fields.supplier')"
									v-model="maintenancePlan.Suppliers"
									:data="supplierList"
									:selected="maintenancePlan.Suppliers"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.supplier_booked')}}
									<InputDynamic
									:fullWidth="true"
									:type="'checkbox'"
									:value="false"
									:disabled="true"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.plan.fields.booked_date')}}
									<InputDynamic
									:fullWidth="true"
									:type="'date'"
									:placeholder="$t('administration.asset.plan.fields.booked_date')"
									:disabled="true"
									/>
								</label>
							</GlobCol>
						</GlobRow>
						<p class="text-danger mas-mt-2" v-if="maintenancePlanError">{{maintenancePlanError}}</p>
						<button @click="saveMaintenancePlan()" class="button mas-mt-4" :disabled="isMaintenancePlanSaveButtonDisabled">{{$t('general.button.save')}}</button>
						<button class="button button--orange" @click="clearMaintenancePlanForm()">{{$t('general.button.clear')}}</button>
					</template>
					<GlobTable
						class="mas-mt-4"
						:maxHeight="50"
						:itemRenderFunctions="maintenancePlanRenderFunctions"
						:data="{
							head : materialPlanTableHead,
							body : maintenancePlans
						}"
						:actions="materialPlanTableActions"
					/>
				</GlobXpanel>
			</template>
			
			<!-- Asset service reasons -->
			<template v-slot:tab-panel-servicereasons>
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobRow>
						<template v-if="!showAddAssetServiceReasonForm">
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.service_reason.fields.select_reason_to_add')}}*
									<span class="add-extra-item" @click="showAddAssetServiceReasonForm = true"><i
															class="fas fa-plus-circle"></i></span>
									<InputDynamic :fullWidth="true" 
										:hideInput="true"
										:type="'virtualselect'"
										:placeholder="$t('administration.asset.service_reason.fields.select_reason_to_add')"
										v-model="serviceReasonToAdd"
										:data="assetServiceReasons" />
								</label>
							</GlobCol>
						</template>
						<template v-else>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.service_reason.create_service_reason')}}*
								<InputDynamic 
											:fullWidth="true" 
											:type="'text'"
											:placeholder="$t('administration.asset.service_reason.fields.name')+'*'"
											v-model="assetServiceReason.Name" />
								</label>
								<button @click="addAssetServiceReason()" class="button button--small mas-mt-2"
													:disabled="!assetServiceReason.Name">{{$t('general.button.save')}}</button>
								<button @click="showAddAssetServiceReasonForm = false"
									class="button button--small button--orange">{{$t('general.button.cancel')}}</button>
								</GlobCol>
							</template>
					</GlobRow>
					<GlobTable
						class="mas-mt-4"
						:maxHeight="50"
						:data="{
							head : assetServiceReasonHead,
							body : assetServiceReasonBody
						}"
						:itemRenderFunctions="assetServiceRenderFunctions"
						:actions="assetServiceReasonActions"
					/>
				</GlobXpanel>
			</template>

			<!-- surveillance service reasons -->
			<template v-slot:tab-panel-surveillance>
				<GlobXpanel :noShadow="true" :avoidHR="true" :tools="false">
					<div>
					<p class="section-heading">{{ $t('administration.asset.surveillance.general') }}</p>
					<GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.surveillance.fields.interface')}}*
								<InputDynamic :fullWidth="true" 
									:type="'virtualselect'"
									:data="oeeInterfaces"
									@change="surveillanceInterfaceChanged()"
									:placeholder="$t('administration.asset.surveillance.fields.interface')" 
									v-model="surveillance.Interface" />
							</label>
						</GlobCol>
						<GlobCol :medium="3" class="surveillance-dont-report-input">
							<label>
								{{$t('administration.asset.surveillance.fields.dont_report_on_this')}}
								
							</label>
							<InputSwitch
									v-model="surveillance.PreventReporting"
									:toggles="{on: $t('general.yes'), off: $t('general.no')}"
									:width="80"
									:value="surveillance.PreventReporting"
								/>
						</GlobCol>
					</GlobRow>
					<GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.surveillance.fields.server')}}*
								<span class="add-extra-item" @click="showAddSurveillanceServerForm = !showAddSurveillanceServerForm"><i
										class="fas fa-plus-circle"></i></span>
								<InputDynamic :fullWidth="true" 
									:type="'virtualselect'"
									:data="oeeServers"
									:placeholder="$t('administration.asset.surveillance.fields.server')"
									v-model="surveillance.OeeServerId" />
							</label>
						</GlobCol>
					</GlobRow>
					<template v-if="showAddSurveillanceServerForm">
						<p> {{$t('administration.asset.surveillance.add_new_server')}} </p>
						<GlobRow class="add-new-server-section">
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.surveillance.fields.alias')}}
									<InputDynamic :fullWidth="true" :type="'text'"
										:placeholder="$t('administration.asset.surveillance.fields.alias')"
										v-model="newServer.Alias" />
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.surveillance.fields.server')}}*
									<InputDynamic :fullWidth="true" :type="'text'"
										:placeholder="$t('administration.asset.surveillance.fields.server')"
										v-model="newServer.Server" />
								</label>
							</GlobCol>
							<GlobCol :medium="2">
								<label>
									{{$t('administration.asset.surveillance.fields.port')}}*
									<InputDynamic :fullWidth="true" 
										:type="'number'"
										:preventDecimal="true"
										:placeholder="$t('administration.asset.surveillance.fields.port')"
										v-model="newServer.Port" />
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.surveillance.fields.secure')}}
									
								</label>
								<InputDynamic
										class="mas-mt-1"
										:type="'checkbox'"
										:name="'server-secure'"
										v-model="newServer.UseSecure" />
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.surveillance.fields.username')}}
									<InputDynamic :fullWidth="true" :type="'text'"
										:placeholder="$t('administration.asset.surveillance.fields.username')"
										v-model="newServer.ServerUser" />
								</label>
							</GlobCol>
							<GlobCol :medium="3">
								<label>
									{{$t('administration.asset.surveillance.fields.password')}}
									<InputDynamic :fullWidth="true" :type="'password'"
										:placeholder="$t('administration.asset.surveillance.fields.password')"
										v-model="newServer.ServerPassword" />
								</label>
							</GlobCol>
							<GlobCol :medium="12">
								<button class="button button--small mas-mt-2" :disabled="isNewServerAddSaveButtonDisabled" @click="addSurveillanceServer()">{{$t('general.button.save')}}</button>
								<button @click="newServer = {}; showAddSurveillanceServerForm = false;" class="button button--small button--orange">{{$t('general.button.cancel')}}</button>
							</GlobCol>
						</GlobRow>
					</template>
					</div>
				</GlobXpanel>
				<GlobXpanel :noShadow="true" :avoidHR="true" :tools="false">
					<p class="section-heading">{{ $t('administration.asset.surveillance.topic') }}</p>
					 <GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.surveillance.fields.topic')}}
								<InputDynamic :fullWidth="true" :type="'text'"
								    :disabled="surveillance.Interface == 'mqtt'"
									:placeholder="$t('administration.asset.surveillance.fields.topic')" 
									v-model="surveillance.Topic" />
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.surveillance.fields.alert_at')}}
								<InputDynamic :fullWidth="true" 
									:type="'number'"
									:preventDecimal="true"
									:placeholder="$t('administration.asset.surveillance.fields.alert_at')" 
									v-model="surveillance.AllowedDelay" />
							</label>
						</GlobCol>
					</GlobRow>
					<GlobRow>
						<GlobCol :medium="12">
							<p class="text-danger mas-mt-2" v-if="surveillanceFormError">{{surveillanceFormError}}</p>
						</GlobCol>
						<GlobCol :medium="12">
							<div class="mas-mt-2">
								<button class="button" :disabled="isSurveillanceSaveButtonDisabled" @click="addAssetSurveillance()">{{$t('general.button.save')}}</button>
								<button class="button button--red float-right" v-if="surveillance.Id" @click="$emit('showAssetSurveillanceDeleteModal')">{{$t('general.button.delete')}}</button>
							</div>
						</GlobCol>
					</GlobRow>
				</GlobXpanel>
			</template>

			<!-- Asset stop reasons -->
			<template v-slot:tab-panel-oeereasons>
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.asset.oee_reason.name')}}*
								<InputDynamic 
											:fullWidth="true" 
											:type="'text'"
											:placeholder="$t('administration.asset.oee_reason.name')"
											v-model="assetOeeReason.Name" />
							</label>
							<button @click="addAssetOeeReason()" class="button button--small mas-mt-2"
												:disabled="!assetOeeReason.Name">{{$t('general.button.save')}}</button>
							<button
								class="button button--small button--orange" @click="assetOeeReason = {}">{{$t('general.button.clear')}}</button>
						</GlobCol>
					</GlobRow>
					<GlobTable
						class="mas-mt-4"
						:maxHeight="50"
						:data="{
							head : oeeReasonReasonHead,
							body : oeeReasonReasonBody
						}"
						:actions="oeeReasonActions"
						:sortable="true"
						@sorted="sortedAssetOeeReasons"
					/>
				</GlobXpanel>
			</template>

			<!-- Data handling -->
			 <template v-slot:tab-panel-data>
				<DataTab
					:data="assetSensors"
					:assetId="itemId"
				>

				</DataTab>
			</template>

			<!-- Service logs -->
			<template v-slot:tab-panel-service-logs>
				<ServiceLogTab
					:data="serviceLogs"
				>
				</ServiceLogTab>
			</template>
		</GlobTabs>
    </div>
</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect, watch, toRaw } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import moment from 'moment';
import { useRoute, useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { dynamicTranslation, generateQRCodeContentURL, getSavedFileURLFromPath } from '@/helpers.js';
import { validateForm, validateMaintenancePlanForm, validateSurveillanceForm, validateSurveillanceServerForm } from "@/views/crud/Asset/common.js";
import bgThumb from '@/assets/icon/image-thumbnail.png';

//Tabs
import DataTab from './Tabs/Data.vue';
import ServiceLogTab from './Tabs/ServiceLog.vue';

export default {
  props: ["manager", "router", "itemId", "reloadData", "loading"],
  components: {
	DataTab,
	ServiceLogTab
  },
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.assets);
	const api = useApi();
	const router = useRouter();
	const route = useRoute();

	const notifications = useNotifications();
    const reloadDataLocal = ref(props.reloadData);
	
	watch(() => props.reloadData, (selection, prevSelection) => { 
		getAsset();
	})

	watch(() => props.loading, (selection, prevSelection) => { 
		isLoading.value = true;
	})


	const isLoading = ref(true);
	const errorMessage = ref(null);

	const asset = reactive({
		form: {
			Id: "",
			Name: "",
			AssetCategories: []
		},
	});

	const qrCodeContent = computed(() => {
		if(asset.form.Id){
			return generateQRCodeContentURL('asset', asset.form.Id)
		}else {
			return '';
		}
	})
	const tabs = ref([
			{
				value: "general",
				name: computed(() => tm('administration')['asset']['tabs']['general'])
			},
			{
				value: "attachment",
				name: computed(() => tm('administration')['asset']['tabs']['attachments'])
			},
			{
				value: "articles",
				name: computed(() => tm('administration')['asset']['tabs']['articles'])
			},
			{
				value: "plans",
				name: computed(() => tm('administration')['asset']['tabs']['maintenance_plans'])
			},
			{
				value: "servicereasons",
				name: computed(() => tm('administration')['asset']['tabs']['service_reasons'])
			},
			{
				value: "surveillance",
				name: computed(() => tm('administration')['asset']['tabs']['surveillance'])
			},
			{
				value: "oeereasons",
				name: computed(() => tm('administration')['asset']['tabs']['oee_reasons'])
			},
		 	{
			 	value: "data",
			 	name: computed(() => tm('administration')['asset']['tabs']['data'])
			},
			{
				value: "service-logs",
				name: computed(() => tm('administration')['asset']['tabs']['service_logs'])
			}
			
	]);

	const assetCategories = ref([]);
	const assetGroups = ref([]);
	const assetParents = ref([]);
	const articles = ref([]);
	const serviceDescriptions = ref([]);
	const responsiblePersonsList = ref([]);
	const supplierList = ref([]);
	const articleToAdd = ref('');
	const articleInputSearchString = ref('');
	const assetSensors = ref([]);
	const serviceLogs = ref([]);

	watch(() => articleToAdd.value,  (selection, prevSelection) => {
		if(articleToAdd.value && typeof articleToAdd.value !== 'object'){
			addArticle();
		}
	})

	const attachments = ref([]);

	onMounted(async() => {
		getAsset();
	 });

	 const enlargeQRCodeActive = (val) => {
		 emit('enlargeQRCodeActive', val)
	 }

	 const getAsset = (updateMainList = false) => {
		isLoading.value = true;
		endpoint.value.getById(props.itemId, true).then(function(res){
			if(!res.data){
				notifications.push(
					{
						msg: tm('general')['something_went_wrong']
					},
					"warning",
					false
					);
				router.push(`/admin/view/assets`);
			}
			asset.form = res.data;
			asset.form.AssetCategories = res.data.AssetCategoryRelations.map((item) => {
				return item.AssetCategoryId;
			});

			if(res.data.AssetGroupRelation){
				asset.form.AssetGroup = res.data.AssetGroupRelation.AssetGroupId;
			}

			assetCategories.value = res.asset_categories.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});

			assetGroups.value = res.asset_groups.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});

			assetParents.value = res.assets.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});

			periodicTypes.value = res.periodic_types.map((item) => {
				return {
					name: item.Name,
					value: item.Id,
					item: item
				}
			});

			serviceDescriptions.value = res.service_descriptions.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});

			responsiblePersonsList.value = res.accounts.map((item) => {
				return {
					name: item.LastName ? item.Name+' '+item.LastName : item.Name,
					value: item.Id
				}
			});

			supplierList.value = res.suppliers.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});

			assetSensors.value = res.sensors;

			//Asset article 
			generateAssetArticleTabData(res);

			maintenancePlans.value = res.maintenance_plans;
			assetServiceReasonBody.value = res.data.AssetServiceReasonRelations;
			assetServiceReasons.value = res.asset_service_reasons;

			//OEE
			oeeServers.value = res.oee_servers;
			surveillance.value = res.data.OeeConnection ? res.data.OeeConnection: {};

			//Attachment
			attachments.value = res.attachments;

			//Oee reasosn
			oeeReasonReasonBody.value = res.oee_reasons;

			serviceLogs.value = res.service_logs;

			isLoading.value = false;

			if(updateMainList){
				emit('updated', res.data);
			}
		});
	 };


    const formData = new FormData();



    const data = ref(asset.form);

    const save = () => {
		errorMessage.value = validateForm(asset.form);

		if(errorMessage.value){
			return false;
		}
		
		isLoading.value = true;
		endpoint.value.update(asset.form.Id, asset.form).then((res) => {
			//Check whether any error exists when create item
			if(res && res.success == 0 && res.errors){
				for (const item in res.errors) {
					errorMessage.value = tm(res.errors[item][0]);
					break;
				}
				return false;
			}
			notifications.push(
					{
						msg: tm('administration')['asset']['update_success']
					},
					"success",
					false
			);
			isLoading.value = false;
			getAsset(true)
		});
    };

	const generateAssetArticleTabData = (res) => {
		articles.value = res.articles.map((item) => {
				return {
					name: item.Name+' ('+item.ArticleNumber+')',
					value: item.Id
				}
			});

		assetArticlesBody.value = res.data.AssetArticles;
	}

	const fieldsToEdit = ref([
      { key: "Amount", type: "number" },
    ]);

	const addArticle = () => {
		isLoading.value = true;
		endpoint.value.addArticle(asset.form.Id, {ArticleId: articleToAdd.value}).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['asset']['article_added_asset']
					},
					"success",
					false
			);
			generateAssetArticleTabData(res);
			articleToAdd.value = null
			isLoading.value = false;
		});
	}

	const updateAssetArticle = (obj) => {
		isLoading.value = true;
		endpoint.value.updateArticle(asset.form.Id, obj.item.Id, {Amount: obj.value }).then((res) => {
			isLoading.value = false;
		});
	}


	const assetArticlesBody = ref([]);
	const assetArticlesHead = ref([
			{
				Alias: "ArticleName",
				Value: computed(() => tm('administration')['asset']['fields']['article'])
			},
			{
				Alias: "Amount",
				Value: computed(() => tm('administration')['asset']['fields']['amount'])
			},
	]);

	const renderFunctions = computed(()=> {
		return {
			ArticleName(obj){
				return obj.Article.Name+' ('+obj.Article.ArticleNumber+')';
			},
		}
	})

	const actions = computed(() => { 
	 return [
		{
			name: tm('general')['button']['delete'],
			color: "red",
			action: (obj) => {
				isLoading.value = true;
				endpoint.value.removeArticle(obj.AssetId, obj.Id).then((res) => {
					notifications.push(
						{
							msg: tm('administration')['asset']['article_removed_from_asset']
						},
						"success",
						false
					);
					generateAssetArticleTabData(res);
					isLoading.value = false;
				});
			}
		},
		];
	});

	//Add category
	const showAssetCategoryAddForm = ref(false);
	const newCategoryName = ref(null);
	const newCategoryNameError = ref(false);

	const addNewCategory = () => {
		newCategoryNameError.value = false;
		if(newCategoryName.value.length > 50){
			newCategoryNameError.value = dynamicTranslation(tm('general.errors.max_length'),
			 {
				attribute: tm('administration.asset.fields.asset_category'),
				value: 50
			 }
			)
			return false;
		}
		isLoading.value = true;
		endpoint.value.createCategory({Name:newCategoryName.value}).then((res) => {
			assetCategories.value.push({
				name: res.data.Name,
				value: res.data.Id,
			})
			asset.form.AssetCategories.push(res.data.Id)
			showAssetCategoryAddForm.value = false;
			isLoading.value = false;
		})
	}

	//Add group
	const showAssetGroupAddForm = ref(false);
	const newGroupName = ref(null);
	const newGroupNameError = ref(false);

	const addNewGroup = () => {
		newGroupNameError.value = false;
		if(newGroupName.value.length > 50){
			newGroupNameError.value = dynamicTranslation(tm('general.errors.max_length'),
			 {
				attribute: tm('administration.asset.fields.asset_category'),
				value: 50
			 }
			)
			return false;
		}
		isLoading.value = true;
		endpoint.value.createGroup({Name:newGroupName.value}).then((res) => {
			assetGroups.value.push({
				name: res.data.Name,
				value: res.data.Id,
			})
			asset.form.AssetGroup = res.data.Id;
			showAssetGroupAddForm.value = false;
			isLoading.value = false;
		})
	}


	//Maintenance plans

	const showAddPlanForm = ref(false);
	const showPlanDeleteConfirmModal = ref(false)
	const maintenancePlanError = ref(false);

	const addServiceDescription = ref(false);

	const serviceDescriptionInitialState = {
		Name: '',
		Description: ''
	}
	const serviceDescription = reactive({...serviceDescriptionInitialState})
	
	const isServiceDescriptionSaveButtonDisabled = computed(() => {
		return !serviceDescription.Name || !serviceDescription.Description ? true : false;
	})

	watch(() => addServiceDescription.value, (selection, prevSelection) => { 
		maintenancePlan.MaintenanceServiceDescriptionId = '';
	})

	  const maintenancePlanExplanation = computed(() => {
		let explanation = '';
		if (periodicTypes.value && periodicTypes.value.length)
		{
			if (maintenancePlan.PeriodicTypeId && maintenancePlan.Frequency)
			{
				let selectedPeriod = periodicTypes.value.find(i => i.value == maintenancePlan.PeriodicTypeId);
				if (maintenancePlan.Frequency == 1)
				{
					explanation = tm('administration')['asset']['plan']['explanation']['single'][selectedPeriod.item.Code];
				} else {
					explanation = tm('administration')['asset']['plan']['explanation']['multiple'][selectedPeriod.item.Code];
					explanation = explanation.replace('{frequency}', maintenancePlan.Frequency);
				}
				
			}
		}

		return explanation;
	})
		

	const addNewServiceDescription = () => {
		isLoading.value = true;
		api.serviceDescriptions.create(serviceDescription).then((res) => {
			 
			serviceDescriptions.value.push({
				name: res.service_description.Name,
				value: res.service_description.Id,
			})

			maintenancePlan.MaintenanceServiceDescriptionId = res.service_description.Id;
			Object.assign(serviceDescription, serviceDescriptionInitialState);
			addServiceDescription.value = false;
			isLoading.value = false;
		});
	}

	const periodicTypes = ref([]);
	const materialPlanTableHead = ref([
			{
				Alias: "Name",
				Value: computed(() => tm('administration')['asset']['plan']['fields']['name'])
			},
			{
				Alias: "Period",
				Value: computed(() => tm('administration')['asset']['plan']['fields']['period'])
			},
			{
				Alias: "StartDate",
				Value: computed(() => tm('administration')['asset']['plan']['fields']['start_date'])
			},
			{
				Alias: "StopDate",
				Value: computed(() => tm('administration')['asset']['plan']['fields']['stop_date'])
			},
			{
				Alias: "FirstServiceDate",
				Value: computed(() => tm('administration')['asset']['plan']['fields']['first_service_date'])
			},
			{
				Alias: "ServicePlanName",
				Value: computed(() => tm('administration')['asset']['plan']['fields']['service_plan'])
			},
	]);

	const maintenancePlanRenderFunctions = computed(()=> {
		return {
			Period(obj){
				return obj.PeriodicType.Name;
			},
			ServicePlanName(obj){
				return obj.MaintenanceServiceDescription ? obj.MaintenanceServiceDescription.Name : '';
			},
		}
	})


	const maintenancePlans = ref([]);
	const maintenancePlanServiceLogExists = ref(false);

	const materialPlanTableActions = computed(() => { 
	 return [

			{
				name: tm('general')['button']['edit'],
				color: "blur",
				action: (obj) => {
					isLoading.value = true;
					showAddPlanForm.value = false;
					clearMaintenancePlanForm();
					Object.assign(maintenancePlan, obj);
					maintenancePlan.ResponsiblePersons = obj.ResponsiblePersons.map(item => item.AccountId);
					maintenancePlan.Suppliers = obj.Suppliers.map(item => item.Id);
					if(obj.ServiceLogs.length){
						maintenancePlanServiceLogExists.value = true;
					}else{
						maintenancePlanServiceLogExists.value = false;
					}
					setTimeout(() => {
						showAddPlanForm.value = true;
						isLoading.value = false;
					}, 300)
					
				}
			},
			{
				name: tm('general')['button']['delete'],
				color: "red",
				action: (obj) => {
					showAddPlanForm.value = false;
					clearMaintenancePlanForm();
					emit('showPlanDeleteConfirmModal', obj.Id)
				}
			},
		];
	});

	const maintenancePlanInitialState = {
		Id: '',
		Name: '',
		MaintenanceServiceDescriptionId: '',
		PeriodicTypeId: '',
		Frequency: 1,
		FirstServiceDate: moment().format('YYYY-MM-DD'),
		LatestExecutionTime: null,
		LatestExecutionTimeEnabled: false,
		StartDate: moment().format('YYYY-MM-DD'),
		StopDate: moment().add('years', 5).format('YYYY-MM-DD'),
		ReminderDays: 2,
		ResponsiblePersons: [],
		AlertByPhone: true,
		AlertByEmail: true,
		Suppliers: []
	};

	const maintenancePlan = reactive({...maintenancePlanInitialState})

	const isMaintenancePlanSaveButtonDisabled = computed(() => {
		return !maintenancePlan.Name || 
			   !maintenancePlan.PeriodicTypeId || 
			   !maintenancePlan.Frequency || 
			   !maintenancePlan.StartDate || 
			   !maintenancePlan.StopDate || 
			   !maintenancePlan.FirstServiceDate ||
			   !maintenancePlan.ReminderDays ||
			   !(maintenancePlan.ResponsiblePersons && maintenancePlan.ResponsiblePersons.length) ? true : false

	})

	const clearMaintenancePlanForm = () => {
		Object.assign(maintenancePlan, maintenancePlanInitialState);
	}
	const saveMaintenancePlan = () => {
		// validateMaintenancePlanForm
		maintenancePlanError.value = validateMaintenancePlanForm(maintenancePlan);
		if(maintenancePlanError.value){
			return false;
		}
		isLoading.value = true;

		endpoint.value.saveOrUpdateMaintenancePlan(props.itemId, maintenancePlan).then((res) => {
			notifications.push(
						{
							msg: !maintenancePlan.Id ?  tm('administration')['asset']['plan']['create_success'] :  tm('administration')['asset']['plan']['update_success']
						},
						"success",
						false
			);
			
			maintenancePlans.value = res.maintenance_plans;
			showAddPlanForm.value = !showAddPlanForm.value;
			clearMaintenancePlanForm();
			isLoading.value = false;
		});
	}

	//Service reasons

	const serviceReasonToAdd = ref(null);
	const assetServiceReasons = ref([]);
	const showAddAssetServiceReasonForm = ref(false);
	const assetServiceReason = reactive({
		'Name': ''
	});

	const assetServiceReasonHead = ref([
			{
				Alias: "AssetServiceName",
				Value: computed(() => tm('administration')['asset']['service_reason']['fields']['service_reason'])
			},
	]);
	const assetServiceRenderFunctions = computed(()=> {
		return {
			AssetServiceName(obj){
				return obj.AssetServiceReason.Name;
			},
		}
	});
	const assetServiceReasonBody = ref([]);
	
	const assetServiceReasonActions = computed(() => { 
	 return [
			{
				name: tm('general')['button']['delete'],
				color: "red",
				action: (obj) => {
					emit('showAssetServiceReasonDeleteModal', obj.Id)
				}
			},
		];
	});

	watch(() => serviceReasonToAdd.value,  (selection, prevSelection) => {
		if(serviceReasonToAdd.value && typeof serviceReasonToAdd.value !== 'object'){
			addAssetServiceReason(serviceReasonToAdd.value);
		}
	})

	const addAssetServiceReason = (assetServiceReasonId = null) => {
		isLoading.value = true;
		let data = [];
		if(assetServiceReasonId){
			data = {
				'AssetServiceReasonId': assetServiceReasonId
			}
			serviceReasonToAdd.value = null
		}else{
			data = assetServiceReason;
		}

		endpoint.value.addServiceReason(props.itemId, data).then((res) => {
			
			notifications.push(
						{
							msg: tm('administration')['asset']['service_reason']['add_to_asset_success'] 
						},
						"success",
						false
			);
			
			assetServiceReasonBody.value = res.asset_service_reason_relations;
			assetServiceReasons.value = res.asset_service_reasons;
			
			showAddAssetServiceReasonForm.value = false;
			assetServiceReason.Name = '';
			
			isLoading.value = false;
		})
	}

	//Surveillance tab

	const surveillance = ref({});
	const surveillanceFormError = ref(null);
	const newServer = ref({});
	const newServerFormError = ref(null);
	const showAddSurveillanceServerForm = ref(false);
	const oeeInterfaces = ref([
		{
			'value': 'mqtt',
			'name': 'Mqtt'
		}
	])
	const oeeServers = ref([]);

	const isNewServerAddSaveButtonDisabled = computed(() => !newServer.value.Server || !newServer.value.Port ? true : false)
	const isSurveillanceSaveButtonDisabled = computed(() => !surveillance.value.Interface || !surveillance.value.OeeServerId ? true : false)
	

	watch(() => surveillance.value.Interface, (selection, prevSelection) => { 
		surveillanceInterfaceChanged();
	})

	const surveillanceInterfaceChanged = () => {
		 if(surveillance.value.Interface == 'mqtt')
		 {
			 surveillance.value.Topic = 'maintenance/'+asset.form.CustomerId+'/'+asset.form.Uuid;
		 }else{
			surveillance.value.Topic = '';
		 }
	}

	const addSurveillanceServer = () => {
		newServerFormError.value = validateSurveillanceServerForm(newServer.value);
		if(newServerFormError.value){
			return false;
		}
		isLoading.value = true;
		endpoint.value.addSurveillanceServer(props.itemId, newServer.value).then((res) => {
			
			oeeServers.value.push({
				'name': res.data.Server,
				'value': res.data.Id
			})

			surveillance.value.OeeServerId = res.data.Id;
			newServer.value = {};
			showAddSurveillanceServerForm.value = false;
			isLoading.value = false;
		})
	}
	
	
	const addAssetSurveillance = () => {
		surveillanceFormError.value = validateSurveillanceForm(surveillance.value);
		if(surveillanceFormError.value){
			return false;
		}
		isLoading.value = true;
		endpoint.value.addOrUpdateAssetSurveillance(props.itemId, surveillance.value).then((res) => {
			notifications.push(
						{
							msg: !surveillance.value.Id ?  tm('administration')['asset']['surveillance']['create_success'] :  tm('administration')['asset']['surveillance']['update_success']
						},
						"success",
						false
			);

			surveillance.value = res.data;
			isLoading.value = false;
		})
	}

	//Attachments

	const downloadFile = (path, filename) => {
		const url = getSavedFileURLFromPath(path);
		const link = document.createElement("a");
		link.target = '_blank';
		link.href = url;
		link.download = filename;
		link.click();
	}

	const removeFileFromAsset = (fileLibraryId) => {
		isLoading.value = true;
		endpoint.value.removeFileFromAsset(props.itemId, fileLibraryId).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['asset']['file_library_modal']['file_remove_success']
					},
					"success",
						false
					);
			attachments.value = res.attachments;
			isLoading.value = false;
		})
	}

	  const addFileToAsset = (fileLibraryId) => {
			isLoading.value = true;
			endpoint.value.addFileToAsset(props.itemId, fileLibraryId).then((res) => {
				notifications.push(
					{
						msg: tm('administration')['asset']['file_library_modal']['file_add_success']
					},
					"success",
					false
				);
				attachments.value = res.attachments;
				isLoading.value = false;
			})
	  }

	const attachedFiles = computed(() => {
		return attachments.value.map(file => parseInt(file.FileLibraryId))
	})

	const attachmentAddedOrRemoved = (item) => {
		if (item.value) {
			addFileToAsset(item.item.Id);
		} else {
			removeFileFromAsset(item.item.Id);
		}
	}

	const sortingCallback = (items) => {
      
	   let ids = items.map(i => i.Id);
	   isLoading.value = true;
	   endpoint.value.sortFiles(props.itemId, {ids: ids}).then((res) => {
		   isLoading.value = false;
	   })
    };

	//Oee reason 

	const assetOeeReason = ref({
		'Id': '',
		'Name': ''
	});

	const oeeReasonReasonHead = computed(() => {
		return [
			{
				Alias: 'Name',
				Value: tm('administration')['asset']['oee_reason']['name']
			},
		]
	})

	const oeeReasonReasonBody = ref([]);

	const oeeReasonActions = computed(() => {
		return [
			{
				name: tm('general')['button']['edit'],
				color: "green",
				action: (obj) => {
					assetOeeReason.value = obj;
				}
			},
			{
				name: tm('general')['button']['delete'],
				color: "red",
				action: (obj) => {
					emit('showAssetOeeReasonDeleteModal', obj.Id)
				}
			},
		];
	});

	const addAssetOeeReason = () => {
		isLoading.value = true;
		endpoint.value.addOrUpdateAssetOeeReason(props.itemId, assetOeeReason.value).then((res) => {
			notifications.push(
						{
							msg: !assetOeeReason.value.Id ?  tm('administration')['asset']['oee_reason']['create_success'] :  tm('administration')['asset']['oee_reason']['update_success']
						},
						"success",
						false
			);

			assetOeeReason.value = {};
			oeeReasonReasonBody.value = res.data;
			isLoading.value = false;
		})
	}

	const sortedAssetOeeReasons = (items) => {
		items = items.map(i => i.Id);
		isLoading.value = true;
		endpoint.value.updateOeeReasonSortOrder(props.itemId, {
			ids: items
		}).then((res) => {
			isLoading.value = false;
		})
	}

    return {
	  isLoading,
	  qrCodeContent,
	  enlargeQRCodeActive,
	  tabs,
	  fieldsToEdit,
      save,
	  errorMessage,
	  asset,
	  reloadDataLocal,
	  assetCategories,
	  articles,
	  articleToAdd,
	  updateAssetArticle,
	  articleInputSearchString,
	  assetGroups,
	  serviceDescriptions,
	  assetParents,
	  ...props,
	  assetArticlesHead,
	  assetArticlesBody,
	  renderFunctions,
	  actions,
	  //Add category
	  showAssetCategoryAddForm,
	  newCategoryName,
	  newCategoryNameError,
	  addNewCategory,

	  //Add group
	  showAssetGroupAddForm,
	  newGroupName,
	  newGroupNameError,
	  addNewGroup,

	  //Plans
	  supplierList,
	  materialPlanTableHead,
	  maintenancePlans,
	  materialPlanTableActions,
	  showAddPlanForm,
	  addServiceDescription,
	  maintenancePlan,
	  saveMaintenancePlan,
	  isMaintenancePlanSaveButtonDisabled,
	  clearMaintenancePlanForm,
	  periodicTypes,
	  serviceDescription,
	  isServiceDescriptionSaveButtonDisabled,
	  addNewServiceDescription,
	  maintenancePlanError,
	  maintenancePlanRenderFunctions,
	  responsiblePersonsList,
	  maintenancePlanServiceLogExists,
		showPlanDeleteConfirmModal,
	  maintenancePlanExplanation,
	  
	  //service reasons
	  serviceReasonToAdd,
	  assetServiceReasons,
	  assetServiceReasonHead,
	  assetServiceReasonBody,
	  assetServiceReasonActions,
	  addAssetServiceReason,
	  showAddAssetServiceReasonForm,
	  assetServiceReason,
	  assetServiceRenderFunctions,

	  //Survelliance
	  surveillance,
	  newServer,
	  showAddSurveillanceServerForm,
	  oeeInterfaces,
	  oeeServers,
	  isSurveillanceSaveButtonDisabled,
	  addAssetSurveillance,
	  surveillanceFormError,
	  addSurveillanceServer,
	  isNewServerAddSaveButtonDisabled,
	  newServerFormError,
	  surveillanceInterfaceChanged,

	  //Attachments
	  attachments,
	  downloadFile,
	  addFileToAsset,
	  removeFileFromAsset,
	  sortingCallback,
	  bgThumb,
	  getSavedFileURLFromPath,
		attachmentAddedOrRemoved,
	  attachedFiles,

	  //Oee reson
	  assetOeeReason,
	  oeeReasonReasonHead,
	  oeeReasonReasonBody,
	  oeeReasonActions,
	  addAssetOeeReason,
	  sortedAssetOeeReasons,

	  // Data/ Sensors
	  assetSensors,

	  //Service logs
	  serviceLogs
    };
  },
};
</script>

<style lang="scss" scoped>
	.add-new-server-section{
		padding: 14px 14px 14px 14px !important;
    	border: 1px dashed;
	}

	//Attachments tabs
.select-upload-library-file.border{
	border-bottom: 1px dashed $mas-gunmetalBlack !important;
}
.select-upload-library-file{
	display: grid;
	div{
		display: inline-flex;
	}
	i{
		margin-left: 9px;
		cursor: pointer;
		font-size: 18px;
	}
	i.blue{
		color: $mas-traditionalBlue;
	}
	p{
		margin: 0;
    	margin-bottom: 15px;
		min-width: 8rem;

	}
}
.add-file{
	border-top: 1px dashed $mas-gunmetalBlack !important;

	.thumb{
		cursor: pointer;
		background-size: cover !important;
		background-position: center;
		background-repeat: no-repeat;
		height: 100px;
        width: 100px;
		position: relative;

		.image-background{
			span.add{
				right: -9%;
    			top: -6%;
			}
		}

		.remove{
			    background-color: red;
				font-size: 28px;
				font-weight: 700;
				border-radius: 50px;
				width: 23px;
				height: 23px;
				color: white;
				display: flex;
				align-items: baseline;
				justify-content: center;
				padding: 0;
				line-height: 18px;
				position: absolute;
				right: -8%;
                top: -8%;
		}
	}
}

p.maintenance-plan-explanation{
	color: $mas-solidRed;
    margin: 0;
    margin-top: 31px;
    font-weight: bold;
}


.surveillance-dont-report-input{
	display: flex;
    flex-direction: column;
    row-gap: 4px;
}
</style>