import Report from "@/views/Report/Index.vue";
import WeeklyReport from "@/views/Report/Weekly/Index.vue";

export const reports = {
  path: "/report",
  component: Report,
  name: "Report",
  nav: {
    name: "general.menu.reports",
    icon: "chart-bar"
  },
  meta: {
    noPadding: true
  },
  children: [
    {
        path: '/report/weekly',
        component: WeeklyReport,
        nav: {
            name: 'general.menu.weekly'
        },
        meta: {}
    }

  ]
};
    

