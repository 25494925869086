<template>
    <GlobModal :widthInPx="800" :title="$t('maintenance_plan.service_log_menu.view.heading')" 
        @close="$emit('close')" 
        :show="true">
			<GlobSpinner :isLoading="isServiceLogModalLoading" />
			<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true" class="mas-service-log-view">
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobRow>
						<GlobCol :medium="12" v-if="userDescription">
							<p class="section-head input-text">
								{{$t('maintenance_plan.service_log_menu.view.user_description')}}
							</p>
							<div class="description">
								{{userDescription}}
							</div>
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceLog && serviceLog.service_log_responsible && serviceLog.service_log_responsible.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.executed_by')}}
							</p>
							<GlobRow v-for="logResponsible in serviceLog.service_log_responsible" :key="'service-log-view-responsible-'+logResponsible.Id" class="service-log-respoisble-persons">	
								<GlobCol :medium="3">
									<label>
										{{$t('maintenance_plan.service_log_menu.view.user')}}
										<h4>{{logResponsible.account.Name}}</h4>
									</label>
								</GlobCol>
								<GlobCol :medium="3">
									<label>
										{{$t('maintenance_plan.service_log_menu.view.started')}}
										<h4>{{moment(logResponsible.StartTime).format('YYYY-MM-DD HH:mm')}}</h4>
									</label>
								</GlobCol>
								<GlobCol :medium="3">
									<label>
										{{$t('maintenance_plan.service_log_menu.view.stopped')}}
										<h4>{{logResponsible.StopTime ? moment(logResponsible.StopTime).format('YYYY-MM-DD HH:mm') : ''}}</h4>
									</label>
								</GlobCol>
								<GlobCol :medium="3">
									<label>
										{{$t('maintenance_plan.service_log_menu.view.total_time')}}
										<h4>{{logResponsible.TotalTime ? logResponsible.TotalTime : 0 }} {{$t('maintenance_plan.service_log_menu.view.minutes')}}</h4>
									</label>
								</GlobCol>
							</GlobRow>
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceDescription">
							<p class="section-head input-text">
								{{$t('maintenance_plan.service_log_menu.view.service_description')}}
							</p>
							<div class="description">
								{{serviceDescription}}
							</div>
						</GlobCol>
						<GlobCol :medium="12">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.comments')}}
							</p>
							<div class="description">
								{{serviceLog.Comment}}
							</div>
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceLog && serviceCheckList.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.check_list')}}
							</p>
							 <div class="check-list">
								<div class="check-list-item" v-for="checkListItem in serviceCheckList" :key="'check-list-'+checkListItem.Id">
									<span v-if="serviceLog && serviceLog.service_log_check_list && serviceLog.service_log_check_list.find(i => i.MaintenanceServiceDescriptionCheckListId == checkListItem.Id)">
										<i class="fas fa-check"></i>
									</span>
									<span class="not-completed-checklist" v-else>
										<i class="fas fa-times"></i>
									</span>
									<p>{{checkListItem.Description}}</p>
								</div>
							</div>
							
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceLog && serviceLog.attachments && serviceLog.attachments.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.uploaded_images')}}
							</p>
							<GlobRow class="file-library-section">
								<GlobCol class="files-section " :medium="3" v-for="attachment in serviceLog.attachments" :key="'library-file-'+attachment.Id">
									<div class='parent'>
										<p>{{attachment.Name.length > 8 ? attachment.Name.slice(0, 8)+'...' : attachment.Name}}</p>
										<div class="thumb" :style="'background-image: url('+bgThumb+')'" @click="downloadFile(attachment.URL, attachment.Name)"></div>						
									</div>
								</GlobCol>
							</GlobRow>
							
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceLog && serviceLog.assetServiceReasons && serviceLog.assetServiceReasons.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.service_reasons')}}
							</p>
							<GlobRow>
								<GlobCol :medium="12">
									<span v-for="(assetServiceReason, index) in serviceLog.assetServiceReasons" :key="'service-view-asset-reason-'+index" class="multiselect__tag">
										{{assetServiceReason}}
									</span>
								</GlobCol>
							</GlobRow>
							
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceLog && serviceLog.service_tag_relations && serviceLog.service_tag_relations.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.tags')}}
							</p>
							<GlobRow>
								<GlobCol :medium="12">
									<span v-for="(tagRelation, index) in serviceLog.service_tag_relations" :key="'service-view-tag-'+index" class="multiselect__tag">
										{{tagRelation.service_tag.Name}}
									</span>
								</GlobCol>
							</GlobRow>
							
						</GlobCol>
					</GlobRow>
				</GlobXpanel>
			</GlobXpanel>
		</GlobModal>
</template>

<script setup>
import { ref, defineProps, computed, onMounted } from "vue";
import { useApi } from "@/plugins/api";
import moment from "moment";
const isServiceLogModalLoading = ref(true);

const props = defineProps({
    serviceLogId: {
        type: Number,
        required: true
    }
});

const api = useApi();
const endpoint = computed(() => api.serviceLogs);
const serviceLog = ref({});
const serviceCheckList = ref([]);
const userDescription = ref('');
const serviceDescription = ref('');

onMounted(async () => {
    endpoint.value.getDetails(props.serviceLogId).then((res) => {
        serviceLog.value = res.service_log;
        serviceCheckList.value = res.service_check_list;
        isServiceLogModalLoading.value = false;
		userDescription.value = res.user_description;
		serviceDescription.value = res.service_description;
    });
});

</script>