<template>
  <div class="ips-table table-scroll" :style="`${ maxHeight ? `max-height: ${maxHeight}vh;` : ''} `">
    <table>
      <TableHead :data="data" :actions="actions" @sorted="data => applySorting(data)" />
      <TableBody
        :data="data"
				:sortingData="sortingData"
        :actions="actions"
        :sortable="true"
        :enableRenderFunctions="enableRenderFunctions"
        :itemRenderFunctions="itemRenderFunctions"
        :rowStylingFunction="rowStylingFunction"
        :enableEditing="enableEditing"
        :fields="fields"
        @sorted="$emit('sorted', $event)"
				@change-item="item => $emit('change-item', item)"
        v-if="data && data.body.length"
      />
    </table>
    <div  v-if="data && !data.body.length" class="empty-table-message">
          {{$t('general.table_is_empty')}}
    </div>
  </div>
</template>

<script>
import { props } from "./table/common";
import TableBody from "./table/components/Body";
import TableHead from "./table/components/Head";
import { reactive } from 'vue';
export default {
  props: props,
	emits: ['change-item'],
  components: {
    TableBody,
    TableHead
  },
  setup(props) {
		const log = val => console.log;
		let sortingData = reactive({key: false, descending: false})

		const applySorting = (data) => {
			sortingData.key = data.key;
			sortingData.descending = data.descending;
		}
		return {
			log,
			applySorting,
			sortingData
		}
	}
};
</script>

<style lang="scss" scoped>
.ips-table {
  font-size: 0.8rem;
  margin-bottom: 0rem;
  position: relative;
  max-height: 70vh;
  @include scrollbar(4px);
  overflow-y: auto;
  table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
  }

  .empty-table-message{
      text-align: center;
      padding: 2rem;
      font-size: 1.5rem;
      opacity: .4;
  }
}
</style>
