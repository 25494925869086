import { dynamicTranslation } from '@/helpers.js';
import { i18n } from '@/plugins/i18n/lang.js'

export const validateForm = (form) =>{
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.ProblemHeading && form.ProblemHeading.length > 200){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['troubleshoot_guide']['fields']['problem_heading'],
         value: 200
      })
      return error;
   }

    if(form.SolutionHeading && form.SolutionHeading.length > 200){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['troubleshoot_guide']['fields']['solution_heading'],
         value: 200
      })
      return error;
   }

   return error;
}