//MachineEfficiency
import MachineEfficiency from "@/views/MachineEfficiency/Index.vue";
import OEE from "@/views/MachineEfficiency/OEE/Index.vue";

export const machineEfficiency = {
  path: "/machine-efficiency",
  component: MachineEfficiency,
  name: "MachineEfficiency",
  nav: {
    name: "general.menu.machine_efficiency",
    icon: "cog"
  },
  meta: {
    noPadding: true
  },
  children: [
        {
            path: '/machine-efficiency/stops',
            component: OEE,
            nav: {
                name: 'general.menu.machine_stops'
            },
            meta: {}
        }
  ]
};
