<template>
    <div>
        <Navbar :items="routes" />
           <GlobRow>
                <GlobCol :medium="12">
                    <GlobFilter
                            @apply="(e) => loadItems(e)"
                            :languageSupportCustomField="true"
                            :inputMinWidth="'250px'"
                            :enable="false"
                            :dateToggle="false"
                            :useDate="false"
                            :assemblyToggle="false"
                            :materialPlanSelectToggle="false"
                            :multiselect="true"
                            :endpoint="filterURL"
                            >
                    </GlobFilter>
                </GlobCol>
               <GlobCol :medium="12">
                    <Note
						:data="notes"
						:isLoading="isLoading"
                        :mainTableMaxHeight="50"
						@update="loadItems(filter)"
					></Note>
                </GlobCol>
            </GlobRow>
    </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useApi } from "@/plugins/api";
import Note from '@/views/Report/Weekly/Note/Index.vue';

const api = useApi();
const endpoint = api.notes;

const notes = ref([]);
const filter = ref(null);

onMounted(() => {
    loadItems();
});

const filterURL =  computed(() => api.filterURLs.notes);
const isLoading = ref(false);

const loadItems = async (e) => {
    isLoading.value = true;

    let body = {filters: {}};
    if (e && e.body) {
        body.filters = e.filters;
        filter.value = e;
    } else{
        filter.value = null;
    }
    endpoint.get({...body}).then((response) => {
        notes.value = response.data;
        isLoading.value = false;
    });
};
</script>