<template>
    <GlobXpanel :title="$t('reports.weekly.machine_stop_reasons')">
      <div id="stopReasonChartdiv"></div>
    </GlobXpanel>
  </template>
  
  <script setup>
  import { ref, watch, onMounted, onBeforeUnmount } from "vue";
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";
  import moment from "moment";
  
  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  });
  
  let chart = null;
  
  const plotChart = () => {
    if (chart) {
      chart.dispose(); // Destroy previous chart instance before recreating
    }
  
    // Create a new chart instance
    chart = am4core.create("stopReasonChartdiv", am4charts.XYChart);
    
    // Group data by date for stacked chart format
    let formattedData = {};
    let addedDates = [];
    props.data.forEach(entry => {
      let dateKey = entry.StartDate; // Keep StartDate as string
      let reasonKey = entry.Reason || "unknown"; // Ensure stop reason is not null
  
      if (!addedDates.includes(dateKey)) {
        formattedData[dateKey] = { date: moment(entry.StartDate).format("MMM DD") }; // Store date
        addedDates.push(dateKey);
      }
      formattedData[dateKey][reasonKey] = entry.MinutesStopped; // Store stop reason's stop time
    });
  
    chart.data = Object.values(formattedData); // Convert object to array
  
    // Configure X-axis (Date as Category)
    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = "date"; // Use string StartDate
    dateAxis.renderer.labels.template.rotation = 90;
    dateAxis.renderer.labels.template.verticalCenter = "middle";
    dateAxis.title.text = "Date";
    dateAxis.renderer.minGridDistance = 50;
  
    // Configure Y-axis (Minutes Stopped)
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Minutes Stopped";
  
    // Get unique stop reasons for stacked series
    const stopReasons = [...new Set(props.data.map(d => d.Reason || "unknown"))];
  
    stopReasons.forEach(reason => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "date";
      series.dataFields.valueY = reason; // Dynamically set reason names as fields
      series.name = reason; // Legend entry
      series.stacked = true;
      series.tooltipText = "{name}: {valueY} min";
    });
  
    // Enable tooltips & cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomX";
  
    // Add legend
    chart.legend = new am4charts.Legend();
  };
  
  // Watch for data changes and update the chart
  watch(() => props.data, (newVal) => {
    if (newVal.length) {
      plotChart();
    }
  }, { deep: true });
  
  // Initialize chart on mount
  onMounted(() => {
    plotChart();
  });
  
  // Cleanup chart on component unmount
  onBeforeUnmount(() => {
    if (chart) {
      chart.dispose();
    }
  });
  </script>
  
  <style scoped>
  #stopReasonChartdiv {
    width: 100%;
    height: 500px;
  }
  </style>
  