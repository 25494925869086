<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="12">
				<GlobFilter
						@apply="(e) => loadItems(e)"
						:languageSupportCustomField="true"
						:inputMinWidth="'250px'"
						:enable="false"
						:dateToggle="false"
						:useDate="false"
						:assemblyToggle="false"
						:materialPlanSelectToggle="false"
						:multiselect="true"
						:endpoint="filterURL"
						:triggerFilter="triggerFilter"
						>
				</GlobFilter>
			</GlobCol>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('maintenance_plan.maintenance_plans')">
					<button @click="showCreateUnplannedMaintModal = true" class="button button--margin button--orange button--small mas-mb-4 mas-mr-2">{{$t('maintenance_plan.start_unplanned_maintenance')}}</button>
					<button @click="showSinglePlaningModal = true" class="button button--margin button--small button--orange mas-mb-4">{{$t('maintenance_plan.single_planing.single_planing')}}</button>
					<GlobTable
						:maxHeight="50"
						:data="{
							head : mainListHead,
							body : mainList
						}"
						:itemRenderFunctions="renderFunctions"
						:rowStylingFunction="rowStylingFunction"
						:actions="actions"
					/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>

		<!-- Show service logs -->
		<GlobModal :widthInPx="800" :title="$t('maintenance_plan.service_logs.service_logs')" @close="showServiceLogsModal = false" :show="showServiceLogsModal">
			<GlobSpinner :isLoading="isServiceLogModalLoading" />
			<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true">
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobTable
						:maxHeight="50"
						:data="{
							head : servicelogsTableHead,
							body : servicelogsTableBody
						}"
					/>
				</GlobXpanel>
			</GlobXpanel>
		</GlobModal>

		<!-- Execute service -->
		<GlobModal :widthInPx="1100" :title="$t('maintenance_plan.service_execution.service_execution')" @close="showServiceExecuteModal = false" :show="showServiceExecuteModal && !enlargeQRCodeActive" v-if="showServiceExecuteModal">
			<Execute 
				@close="showServiceExecuteModal = false"
				@externalSupplierUpdated="(obj) => externalSupplierUpdated(obj)"
				@maintenanceUpdated = "(id) => maintenanceUpdated(id)"
				@enlargeQRCodeActive= "(val) => enlargeQRCodeActive = val"
				:maintenancePlanCalendarId="excutePlanId"
				:assetId="excutePlanAssetId"/>
		</GlobModal>

		<!-- Start un planned maintenance -->
		<GlobModal :widthInPx="800" :title="$t('maintenance_plan.unplanned.heading')" @close="closeUnplannedServiceStartModal()" :show="showCreateUnplannedMaintModal">
			<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true" class="start-unplanned-maintenance">
				<GlobSpinner :isLoading="isLoadingStartUnPlannedModal"/>
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobRow>
						<GlobCol :medium="6">
							<label>
								{{$t('maintenance_plan.unplanned.fields.asset')}}*
								<InputDynamic
								:fullWidth="true"
								:type="'virtualselect'"
								:placeholder="$t('maintenance_plan.unplanned.fields.asset')"
								v-model="unplannedMaintenance.AssetId"
								:data="assets"
								/>
							</label>
						</GlobCol>
						<GlobCol :medium="6">
							<label>
								{{$t('maintenance_plan.unplanned.fields.service_description')}}
								<InputDynamic
								:fullWidth="true"
								:type="'virtualselect'"
								:placeholder="$t('maintenance_plan.unplanned.fields.service_description')"
								v-model="unplannedMaintenance.ServiceDescriptionId"
								:data="serviceDescriptions"
								/>
							</label>
						</GlobCol>
					</GlobRow>
					<button class="button button--margin button--small button--orange" :disabled="!unplannedMaintenance.AssetId" @click="startUnPlannedService()">{{$t('maintenance_plan.unplanned.next')}}</button>
				</GlobXpanel>
			</GlobXpanel>
		</GlobModal>

		<GlobModal :widthInPx="800" :title="$t('maintenance_plan.single_planing.single_planing')" @close="showSinglePlaningModal = false, selectedPlanIdToEdit = null"
			:show="showSinglePlaningModal" v-if="showSinglePlaningModal">
			<CreateSinglePlan 
			@singlPlanCreated="singlPlanCreated()"
			:maintenancePlanCalendarId="selectedPlanIdToEdit"

			/>
		</GlobModal>

</div>
</template>

<script>
import { onMounted, computed, watch, ref, watchEffect, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useNotifications } from "@/plugins/notifications";

import Execute from './Execute.vue';
import CreateSinglePlan from './CreateSinglePlan.vue';

export default {
components: {
	Execute,
	CreateSinglePlan
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const notifications = useNotifications();
	const router = useRouter();
	const endpoint = computed(() => api.maintenancePlans);
	const filterURL =  computed(() => api.filterURLs.maintenancePlans);
	const todayDate = ref(moment().format('YYYY-MM-DD'))
    
	const enlargeQRCodeActive = ref(false);
	const triggerFilter = ref(false);
	const isLoading = ref(false);
	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "AssetName",
				Value: computed(() => tm('maintenance_plan')['fields']['asset'])
			},
			{
				Alias: "Name",
				Value: computed(() => tm('maintenance_plan')['fields']['name'])
			},
			{
				Alias: "MaintenancePlanName",
				Value: computed(() => tm('maintenance_plan')['fields']['maintenance_plan'])
			},
			{
				Alias: "ExecutionDate",
				Value: computed(() => tm('maintenance_plan')['fields']['execution_date'])
			},
			{
				Alias: "Responsible",
				Value: computed(() => tm('maintenance_plan')['fields']['responsible'])
			},
			{
				Alias: "SupplierBookingConfirmed",
				Value: computed(() => tm('maintenance_plan')['fields']['external_supplier_planned'])
			},
			{
				Alias: "LastService",
				Value: computed(() => tm('maintenance_plan')['fields']['last_service'])
			},

	]);

	const renderFunctions = computed(()=> {
		return {
			SupplierBookingConfirmed(obj){
				if(obj.IsExternalSupplierIncluded || obj.SupplierBookingConfirmed){
					return tm('general')['yes'];
				}else{
					return tm('general')['no'];
				}
			},
			Responsible(obj){
				let responsible = '';
				obj.responsible.map((item)=>{
					if(responsible == ''){
						responsible = item.account.Name;
					}else{
						responsible = responsible+', '+item.account.Name;
					}
				})
				return responsible;
			},
		}
	});

	const excutePlanId = ref(null);
	const excutePlanAssetId = ref(null);
	const showServiceExecuteModal = ref(false);

	const showSinglePlaningModal = ref(false);
	const selectedPlanIdToEdit = ref(null);

	const showServiceLogsModal = ref(false);
	const isServiceLogModalLoading = ref(false);
	const servicelogsTableBody = ref([]);
	const servicelogsTableHead = ref([
			{
				Alias: "Asset",
				Value: computed(() => tm('maintenance_plan')['service_logs']['fields']['asset'])
			},
			{
				Alias: "MaintenancePlan",
				Value: computed(() => tm('maintenance_plan')['service_logs']['fields']['maintenance_plan'])
			},
			{
				Alias: "ServiceBy",
				Value: computed(() => tm('maintenance_plan')['service_logs']['fields']['service_by'])
			},
			{
				Alias: "ServiceDate",
				Value: computed(() => tm('maintenance_plan')['service_logs']['fields']['service_date'])
			}
	]);


	const actions = computed(() => { return [
		{
			icon: 'fas fa-file-alt',
			description: tm('maintenance_plan')['service_logs']['service_logs'],
			color: "#01188d",
				action: (obj) => {
					servicelogsTableBody.value = [];
					isServiceLogModalLoading.value = true;
					showServiceLogsModal.value = true;
					endpoint.value.getServiceLogs(obj.Id).then((res) => {
						
						servicelogsTableBody.value = res.service_logs;
						isServiceLogModalLoading.value = false;
					});
				}
		},
		{
			icon: 'fas fa-play',
			description: tm('maintenance_plan')['execute_service'],
			color: "#ff8c00",
			action: (obj) => {
				excutePlanId.value = obj.Id;
				excutePlanAssetId.value = obj.AssetId;
				showServiceExecuteModal.value = true;	
			}
		},
		{
			icon: 'fas fa-pen',
			description: tm('maintenance_plan')['edit'],
			if: (obj) => {
				return true
			},
			action: (obj) => {
				selectedPlanIdToEdit.value = obj.Id;
				showSinglePlaningModal.value = true;
			}
		},
		];
	});
	
	 onMounted(async() => {
          loadItems();
	 });

	const loadItems = async(e) => {
		isLoading.value = true;

		let body = {filters: {}};
		if (e && e.body) {
			body.filters = e.filters;
		} 
		endpoint.value.get({...body}).then(function(res){
           mainList.value =  _.orderBy(res.data, ['ExecutionDate'],['asc']);
		   isLoading.value = false;
		})
	}

	const rowStylingFunction = (obj) => {
		if(todayDate.value > obj.ExecutionDate){
			return 'exec-date-exceed-plan';
		}
	}

	const externalSupplierUpdated = (obj) => {
		const index = mainList.value.findIndex( i => i.Id == excutePlanId.value);
		mainList.value[index].SupplierBookingConfirmed = obj.SupplierBookingConfirmed;
	}

	const maintenanceUpdated = (id) => {
		isLoading.value = true;
		endpoint.value.get({id: id}).then(function(res){
		   const index = mainList.value.findIndex(i => i.Id == id);
		   if(res.maintenance_plan_calendar){
			   mainList.value[index] = res.maintenance_plan_calendar;
		   }else{
			   let mainListClone = JSON.parse(JSON.stringify(mainList.value))
			   delete mainListClone[index];
			   mainListClone = mainListClone.filter(function(){return true;});
			   mainList.value = mainListClone;
			}
		   
		   isLoading.value = false;
		})
	}

	//Un planned maintenance

	const showCreateUnplannedMaintModal = ref(false);
	const isLoadingStartUnPlannedModal = ref(false);
	const assets = ref([]);
	const serviceDescriptions = ref([]);
	const unplannedMaintenance = reactive({
		'AssetId': '',
		'ServiceDescriptionId': ''
	})

	watch(() => showCreateUnplannedMaintModal.value, () => {
		if(showCreateUnplannedMaintModal.value){
			isLoadingStartUnPlannedModal.value = true;
			api.assets.get().then((res) => {

				assets.value = res.data.map((item) => {
					return {
						name: item.Name,
						value: item.Id
					}
				})
				isLoadingStartUnPlannedModal.value = false;
			})
		}
	})

	 watch(() => unplannedMaintenance.AssetId, () => {
			if(unplannedMaintenance.AssetId && typeof unplannedMaintenance.AssetId != 'object'){
				isLoadingStartUnPlannedModal.value = true;
				// body.filters = ;
				// console.log('body', body)
				api.serviceDescriptions.get({'AssetId': unplannedMaintenance.AssetId}).then((res) => {

					serviceDescriptions.value = res.data.map((item) => {
						return {
							name: item.Name,
							value: item.Id
						}
					})
					isLoadingStartUnPlannedModal.value = false;
				})
			}else{
				serviceDescriptions.value = [];
			}
	});

	const startUnPlannedService = () => {
		isLoadingStartUnPlannedModal.value = true;
		api.assets.startUnplannedMaintenance(unplannedMaintenance.AssetId, {
			'ServiceDescriptionId': unplannedMaintenance.ServiceDescriptionId
		}).then((res) => {
			 notifications.push(
                    {
                        msg: tm('maintenance_plan')['unplanned']['start_service_success']
                    },
                    "success",
                    false
			);

			closeUnplannedServiceStartModal();
			excutePlanId.value = res.maintenance_plan_calendar.Id;
			excutePlanAssetId.value = res.maintenance_plan_calendar.AssetId;
			showServiceExecuteModal.value = true;	
		})
	}

	const closeUnplannedServiceStartModal = () => {
		assets.value = [];
		serviceDescriptions.value = [];
		unplannedMaintenance.AssetId = '';
		unplannedMaintenance.ServiceDescriptionId = '';
		showCreateUnplannedMaintModal.value = false;
		isLoadingStartUnPlannedModal.value = false;

	}

	const singlPlanCreated = () => {
		showSinglePlaningModal.value = false;
		triggerFilter.value = !triggerFilter.value;
	}

    return {
		loadItems,
		endpoint,
		mainList,
		mainListHead,
		renderFunctions,
		isLoading,
		filterURL,
		actions,
		showServiceLogsModal,
		servicelogsTableBody,
		servicelogsTableHead,
		isServiceLogModalLoading,
		rowStylingFunction,
		showServiceExecuteModal,
		showSinglePlaningModal,
		selectedPlanIdToEdit,
		excutePlanId,
		externalSupplierUpdated,
		maintenanceUpdated,
		excutePlanAssetId,
		showCreateUnplannedMaintModal,
		unplannedMaintenance,
		isLoadingStartUnPlannedModal,
		assets,
		serviceDescriptions,
		startUnPlannedService,
		closeUnplannedServiceStartModal,
		singlPlanCreated,
		triggerFilter,
		enlargeQRCodeActive
    };
  }
};
</script>
<style lang="scss">
  .exec-date-exceed-plan{
	  background-color: $mas-lightRed !important;
	  &:hover{
		  background-color: $mas-gainsBoroGray !important;
	  }
  }

  .start-unplanned-maintenance{
	.ips-xpanel__content{
		padding: 21px !important;
    	padding-top: 7px !important;
    	padding-bottom: 35px !important;

		button{
			float: right;
    		margin-right: 14px;
		}
	}
  }
</style>