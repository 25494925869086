<template>
    <GlobModal :widthInPx="800" 
        :title="$t('reports.weekly.note.edit_note')"
        @close="closeModal"
        :show="true"
        :isCRUD="true"
        >
        <GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
            <GlobSpinner :isLoading="isLoading" />
            <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
            <GlobRow>
                <GlobCol :medium="12">
                    <label>
                        {{ $t('reports.weekly.note.heading') }}
                        <InputDynamic :fullWidth="true" :type="'text'"
                            :placeholder="$t('reports.weekly.note.heading')" v-model="form.Heading" />
                    </label>
                </GlobCol>
                <GlobCol :medium="12">
                        <label>
                            {{ $t('reports.weekly.note.machines') }}
                        </label>
                        <InputDynamic 
                            :fullWidth="true" 
                            :type="'multiselect'"
                            v-model="form.Assets"
                            :data="assets"
                            :selected="form.Assets"/>
                </GlobCol>
                <GlobCol :medium="12">
                    <label>
                        {{ $t('reports.weekly.note.note')+'*' }}
                        <InputDynamic :fullWidth="true" 
                            :type="'textarea'"
                            :rows="8"
                            :placeholder="$t('reports.weekly.note.note')" v-model="form.Note" />
                    </label>
                </GlobCol>

                <GlobCol :medium="12">
                    <button class="button mas-mt-4" 
                    @click="saveNote"
                    :disabled="!form.Note">{{
                        $t('general.button.save') }}</button>

                    <button  class="button button--red mas-mt-4"
                        @click="showDeleteConfirmModal = true"
                        >{{
                            $t('general.button.delete') }}</button>
                </GlobCol>
            </GlobRow>
            </GlobXpanel>
        </GlobXpanel>
    </GlobModal>

        <!-- Delete confirm modal -->
    <GlobConsequence :show="showDeleteConfirmModal" @close="showDeleteConfirmModal=false" 
        @confirm="deleteItem()"
        :title="$t('general.modal.delete_note')"
        :message="$t('general.modal.delete_note_confirm')">
    </GlobConsequence>
</template>

<script setup>

import { onMounted, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useNotifications } from "@/plugins/notifications";
import { useI18n } from 'vue-i18n';

const props = defineProps({
    id: {
        type: Number,
        required: true
    }
});

const emits = defineEmits(['close']);
const notifications = useNotifications();
const { tm } = useI18n();

const api = useApi();
const endpoint = api.reports;
const isLoading = ref(false);
const assets = ref([]);
const form = ref({
    Heading: '',
    Note: '',
    Assets: []
});
const showDeleteConfirmModal = ref(false);

const closeModal = (refreshList = false) => {
    emits('close', refreshList);
}

onMounted(() => {
    isLoading.value = true;
    endpoint.getNote(props.id).then((res) => {

        form.value.Heading = res.note.Heading    
        form.value.Note = res.note.NoteText;
        form.value.Assets = res.note.asset_relations.map((asset) => asset.AssetId);

        assets.value = res.assets.map((asset) => {
            return {
                value: asset.Id,
                name: asset.Name
            }
        });
        isLoading.value = false;
    });
});

const saveNote = () => {

    if(form.value.Heading && form.value.Heading.length > 200)
    {
        notifications.push(
            {
                msg: tm('reports')['weekly']['note']['heading_max_length']
            },
            "warning",
            false
        );
        return;
    }

    isLoading.value = true;

    endpoint.updateNote(props.id, form.value).then((res) => {
        notifications.push(
            {
                msg: tm('reports')['weekly']['note']['save_success']
            },
            "success",
            false
        );
        closeModal(true);
        isLoading.value = false;
    });
}

const deleteItem = () => {
    isLoading.value = true;
    showDeleteConfirmModal.value = false;
    endpoint.deleteNote(props.id).then((res) => {
        notifications.push(
            {
                msg: tm('reports')['weekly']['note']['delete_success']
            },
            "success",
            false
        );
        closeModal(true);
        isLoading.value = false;
    });
}


</script>
    
