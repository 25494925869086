<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('administration.asset.assets')">
					<button @click="showCreateModal = true" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</button>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
					<button @click="treeView = !treeView" class="button button--margin button--small float-right">{{ !treeView ? $t('administration.asset.tree_view') : $t('administration.asset.table_view')}}</button>
					<GlobTable
					v-if="!treeView"
						:maxHeight="50"
						:data="{
							head : mainListHead,
							body : filteredItems
						}"
						:actions="actions"
					/>

					<template v-if="treeView">

						<GlobTreeView
							@selected="onTreeItemSelected"	
							@sorted="assetsSorted"
							:initialItems="filteredTreeItems">
						</GlobTreeView>

					</template>

				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

<!-- Create asset modal -->
<GlobModal :widthInPx="1300" :title="$t('administration.asset.create')" @close="showCreateModal = false" :show="showCreateModal" :isCRUD="true" v-if="showCreateModal">
	<Create @created="(asset) => created(asset)" />
</GlobModal>
<!-- Edit asset modal -->
<GlobModal :widthInPx="1500" :isTABS="true" :title="selectedItem.Name ? selectedItem.Name : selectedItem.ArticleNumber" @close="selectedItem = null" :show="selectedItem && selectedItem.Id && !hideEditModal" v-if="selectedItem && selectedItem.Id" :isCRUD="true">
	<Edit 
		:itemId="selectedItem.Id" 
		:loading="itemModalLoading"
		:reloadData="reloadEdit"
		@close="selectedItem = null"
		@updated="(asset) => updated(asset)"
		@enlargeQRCodeActive = "(value) => enlargeQRCodeActive = value"
		@showDeleteConfirmModal="showDeleteConfirmModal = true"
		@showPlanDeleteConfirmModal="(id) => selectedMaintenancePlanId = id"
		@showAssetServiceReasonDeleteModal = "(id) => selectedAssetServiceRelationId = id"
		@showAssetOeeReasonDeleteModal = "(id) => selectedAssetOeeReasonId = id"
		@showAssetSurveillanceDeleteModal = "showAssetSurveillanceDeleteModal = true"
		@showFileLibraryModal="(val) => showFileLibraryModal = val"	
		/>
</GlobModal>
	<!-- Delete confirm modal -->
	<GlobConsequence :show="showDeleteConfirmModal" 
		@close="showDeleteConfirmModal=false"  
		@confirm = "deleteItem()"
		:title="$t('administration.asset.delete_asset')" 
		:message="$t('administration.asset.delete_confirm')"  />
	
	<!-- Delete confirm modal -->
	<GlobConsequence :show="selectedMaintenancePlanId" 
		@close="selectedMaintenancePlanId=null"  
		@confirm = "deleteMaintenancePlan()"
		:title="$t('administration.asset.plan.delete')" 
		:message="$t('administration.asset.plan.delete_confirm')"  />

		<!-- Remove service reason modal -->
	<GlobConsequence :show="selectedAssetServiceRelationId" 
		@close="selectedAssetServiceRelationId=null"  
		@confirm = "removeAssetServiceReason(selectedAssetServiceRelationId)"
		:title="$t('administration.asset.service_reason.remove')" 
		:message="$t('administration.asset.service_reason.remove_confirm')"  />

	<!-- Remove asset survelliance -->
	<GlobConsequence :show="showAssetSurveillanceDeleteModal" 
		@close="showAssetSurveillanceDeleteModal=null"  
		@confirm = "removeAssetSurveillance()"
		:title="$t('administration.asset.surveillance.remove')" 
		:message="$t('administration.asset.surveillance.remove_confirm')"  />


	<!-- Remove oee reason modal -->
	<GlobConsequence :show="selectedAssetOeeReasonId" 
		@close="selectedAssetOeeReasonId=null"  
		@confirm = "removeAssetOeeReason(selectedAssetOeeReasonId)"
		:title="$t('administration.asset.oee_reason.remove')" 
		:message="$t('administration.asset.oee_reason.remove_confirm')"  />


</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import Create from "@/views/crud/Asset/Create.vue";
import Edit from "@/views/crud/Asset/Edit.vue";

export default {
components: {
	Navbar,
	Create,
	Edit
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const treeView = ref(true);
	const endpoint = computed(() => api.assets);
    
	const selectedItem = ref(null);
	const selectedMaintenancePlanId = ref(false);
	const enlargeQRCodeActive = ref(false);

	const showCreateModal = ref(false);
	const showFileLibraryModal = ref(false);
	const showDeleteConfirmModal = ref(false)
	const reloadEdit = ref(false);
	const itemModalLoading = ref(false);
	const hideEditModal = computed(() =>{
		return showDeleteConfirmModal.value || selectedMaintenancePlanId.value || selectedAssetServiceRelationId.value || showAssetSurveillanceDeleteModal.value || enlargeQRCodeActive.value || showFileLibraryModal.value || selectedAssetOeeReasonId.value ? true : false;
	})

	const isLoading = ref(false);
	const notifications = useNotifications();
	const searchString = ref('');
	const treeViewData = ref([]);
	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "Name",
				Value: computed(() => tm('administration')['asset']['fields']['name'])
			},
			{
				Alias: "Manufacture",
				Value: computed(() => tm('administration')['asset']['fields']['manufacture'])
			},
			{
				Alias: "ProductionYear",
				Value: computed(() => tm('administration')['asset']['fields']['production_year'])
			},
			{
				Alias: "InstallationYear",
				Value: computed(() => tm('administration')['asset']['fields']['installation_year'])
			},


		]);
	
	const actions = computed(() => { return [
		{
			name: tm('general')['button']['edit'],
			color: "green",
				action: (obj) => {
					selectedItem.value = obj;
				}
		}
		];
	});
	
	 onMounted(async() => {
          loadMainList();
	 });

	const loadMainList = async(onlyTreeView = false) => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
			if(!onlyTreeView){
				mainList.value = res.data;
			}
		   treeViewData.value = res.tree;
		   isLoading.value = false;
		})
	}

	const filteredItems = computed(() => {
		return searchString.value.length > 0 ? mainList.value
				.filter(
					i => (i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase()))
					||  (i.Manufacture && i.Manufacture.toLowerCase().match(searchString.value.toLowerCase()))
					) : mainList.value;
	})

	const filteredTreeItems = computed(() => {
		return searchString.value.length > 0 ? treeViewData.value
				.filter(
					i => (i.name && i.name.toLowerCase().match(searchString.value.toLowerCase()))
					||  (i.Manufacture && i.Manufacture.toLowerCase().match(searchString.value.toLowerCase()))
					) : treeViewData.value;
	})


	const assetsSorted = (items) => {
		console.log('Sorted items:', items);
		endpoint.value.updateOrder(items);
	}


	const created = (asset) => {
		showCreateModal.value = false;  
		selectedItem.value = asset;
		loadMainList();
	}

	const updated = (asset) => {
		//Update main list
		let index = mainList.value.findIndex((i) => i.Id == asset.Id)
		mainList.value[index].Name = asset.Name;
		mainList.value[index].Manufacture = asset.Manufacture;
		mainList.value[index].ProductionYear = asset.ProductionYear;
		mainList.value[index].InstallationYear = asset.InstallationYear;

		loadMainList(true);
	}

	const deleteItem = () => {
		showDeleteConfirmModal.value = false;
		itemModalLoading.value = !itemModalLoading.value;
		isLoading.value = true;
		endpoint.value.delete(selectedItem.value.Id).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['asset']['delete_success']
					},
					"success",
					false
			);
			selectedItem.value = null;
			loadMainList();
		});
	}


	const onTreeItemSelected = (item) => {
		selectedItem.value = {
			'Id': item.id,
			'Name': item.name
		}
	}

	const deleteMaintenancePlan = () => {
			const planId = selectedMaintenancePlanId.value;
			selectedMaintenancePlanId.value = null;
			itemModalLoading.value = !itemModalLoading.value;
			endpoint.value.deleteMaintenancePlan(planId).then((res) => {
				notifications.push(
					{
						msg: tm('administration')['asset']['plan']['delete_success']
					},
					"success",
					false
				);
				maintenancePlans.value = res.maintenance_plans;
				isLoading.value = false;
			});

			reloadEdit.value = !reloadEdit.value
			

	}

	//Service reason

	const selectedAssetServiceRelationId = ref(false);
	
	const removeAssetServiceReason = (id) => {
		selectedAssetServiceRelationId.value = null
		itemModalLoading.value = !itemModalLoading.value;
		endpoint.value.removeServiceReason(selectedItem.value.Id, id).then((res) => {
			notifications.push(
						{
							msg: tm('administration')['asset']['service_reason']['remove_from_asset_success'] 
						},
						"success",
						false
			);
			reloadEdit.value = !reloadEdit.value
		})
	}

	//Survelliance

	const showAssetSurveillanceDeleteModal = ref(false);
	const removeAssetSurveillance = () => {
		showAssetSurveillanceDeleteModal.value = false;
		itemModalLoading.value = !itemModalLoading.value;
		endpoint.value.removeAssetSurveillance(selectedItem.value.Id).then((res) => {
			notifications.push(
						{
							msg: tm('administration')['asset']['service_reason']['remove_from_asset_success'] 
						},
						"success",
						false
			);
			reloadEdit.value = !reloadEdit.value
		})

	}

	// Oee asset reason

	const selectedAssetOeeReasonId = ref(false);
	const removeAssetOeeReason = (id) => {
		selectedAssetOeeReasonId.value = null
		itemModalLoading.value = !itemModalLoading.value;
		endpoint.value.deleteAssetOeeReason(selectedItem.value.Id, id).then((res) => {
			notifications.push(
						{
							msg: tm('administration')['asset']['oee_reason']['remove_from_asset_success'] 
						},
						"success",
						false
			);
			reloadEdit.value = !reloadEdit.value
		})
	}



    return {
		loadMainList,
		treeViewData,
		filteredItems,
		endpoint,
		mainList,
		mainListHead,
		searchString,
		actions,
		routes,
		isLoading,
		selectedItem,
		enlargeQRCodeActive,
		hideEditModal,
		showCreateModal,
		treeView,
		filteredTreeItems,
		assetsSorted,
		created,
		onTreeItemSelected,
		//archive
		reloadEdit,
		itemModalLoading,
		//delete
		showDeleteConfirmModal,
		deleteItem,
		updated,
		selectedMaintenancePlanId,
		deleteMaintenancePlan,

		//Service reason
		selectedAssetServiceRelationId,
		removeAssetServiceReason,

		//Survelliance
		removeAssetSurveillance,
		showAssetSurveillanceDeleteModal,

		//File library
		showFileLibraryModal,

		//Oee reason
		selectedAssetOeeReasonId,
		removeAssetOeeReason
    };
  }
};
</script>

<style lang="scss">
</style>