//Maintenance
import Maintenance from "@/views/Maintenance/Index.vue";
import Scheduled from "@/views/Maintenance/Scheduled/Index.vue";
import ServiceLog from "@/views/Maintenance/ServiceLog/Index.vue";
import Report from "@/views/Maintenance/Report.vue";

export const maintenancePlan = {
  path: "/maintenance",
  component: Maintenance,
  name: "Maintenance",
  nav: {
    name: "general.menu.maintenance",
    icon: "toolbox"
  },
  meta: {
    noPadding: true
  },
  children: [
        {
            path: '/maintenance/scheduled',
            component: Scheduled,
            nav: {
                name: 'general.menu.maintenance_scheduled'
            },
            meta: {}
        },
        {
            path: '/maintenance/service-log',
            component: ServiceLog,
            nav: {
                name: 'general.menu.maintenance_service_log'
            },
            meta: {}
        },
        {
          path: '/maintenance/reports',
            component: Report,
            nav: {
                name: 'general.menu.reports'
            },
            meta: {}
        },

  ]
};
