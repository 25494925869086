<template>
      <!-- Edit guide modal -->
      <GlobModal :widthInPx="1100" :title="$t('administration.troubleshoot_guide.edit')"
        @close="closeModal()" 
        :show="true"
        :isCRUD="true">
        <GlobSpinner :isLoading="isLoading" />
        <Edit 
            :itemId="id" 
            @updated="(guide) => closeModal(true)"
            @showDeleteConfirmModal="showDeleteConfirmModal = true"
            />
    </GlobModal>
    <!-- Delete confirm modal -->
    <GlobConsequence 
        :show="showDeleteConfirmModal" 
        @close="showDeleteConfirmModal = false" 
        @confirm="deleteItem()"
        :title="$t('administration.troubleshoot_guide.delete_guide')" 
        :message="$t('administration.troubleshoot_guide.delete_confirm')" />
</template>


<script setup>
import { ref } from "vue";
import { useApi } from "@/plugins/api";
import { useNotifications } from "@/plugins/notifications";
import { useI18n } from 'vue-i18n';

import Edit from "@/views/crud/TroubleshootGuide/Edit.vue";

const api = useApi();
const { tm } = useI18n();
const endpoint = api.troubleshootGuides;
const props = defineProps({
    id: {
        type: Number,
        required: true
    }
});
const emits = defineEmits(['close']);
const notifications = useNotifications();



const showDeleteConfirmModal = ref(false);
const isLoading = ref(false);

const closeModal = (refreshList = false) => {
    emits('close', refreshList);
}

const deleteItem = () => {
    isLoading.value = true;
    showDeleteConfirmModal.value = false;
    endpoint.delete(props.id).then(() => {
        notifications.push(
                    {
                        msg: tm('administration')['troubleshoot_guide']['delete_success']
                    },
                    "success",
                    false
                );
        closeModal(true);
        isLoading.value = false;
    });
}

</script>