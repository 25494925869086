<template>
    <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
        <div class="crud-expand">
            <p>{{$t('administration.asset.service_log.service_logs')}}</p>
        </div>
        <GlobTable
            class="mas-mt-4"
            :maxHeight="50"
            :itemRenderFunctions="renderFunctions"
            :data="{
                head : head,
                body : body
            }"
        />
    </GlobXpanel>
</template>

<script setup>

import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { tm } = useI18n();
const props = defineProps({
    data: {
        type: Array,
        required: true
    }
});

const head = computed(() => {
    return [
        {
            Alias: 'MaintenancePlanName',
            Value: tm('administration')['asset']['service_log']['maintenance_plan']
        },
        {
            Alias: 'MaintenancePlanCalendarName',
            Value: tm('administration')['asset']['service_log']['name']
        },
        {
            Alias: 'ServiceDescription',
            Value: tm('administration')['asset']['service_log']['service_description']
        },
        {
            Alias: 'ExecutionDate',
            Value: tm('administration')['asset']['service_log']['service_date']
        },
        {
            Alias: 'ExecutedBy',
            Value: tm('administration')['asset']['service_log']['executed_by']
        },
    ]
})

const renderFunctions = computed(()=> {
    return {
        ExecutedBy(obj){
            let responsible = '';
            obj.service_log_responsible.map((item)=>{
                if(responsible == ''){
                    responsible = item.account.Name;
                }else{
                    responsible = responsible+', '+item.account.Name;
                }
            })
            return responsible;
        },
    }
});

const body = ref(props.data);

</script>