<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="12">
				<GlobFilter
						@apply="(e) => loadItems(e)"
						:languageSupportCustomField="true"
						:inputMinWidth="'250px'"
						:enable="false"
						:dateToggle="false"
						:useDate="false"
						:assemblyToggle="false"
						:materialPlanSelectToggle="false"
						:multiselect="true"
						:endpoint="filterURL"
						>
				</GlobFilter>
			</GlobCol>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('maintenance_plan.oee.overall_equipment_efficiency')">
					<GlobTable
						:maxHeight="50"
						:data="{
							head : mainListHead,
							body : mainList
						}"
						:itemRenderFunctions="renderFunctions"
						:actions="actions"
					/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>

		<!-- Report stop reason modal -->
		<GlobModal :widthInPx="800" class="mas-oee-report" :title="$t('maintenance_plan.oee.report_stop_reason')" @close="showReportModal = false" :show="showReportModal" v-if="showReportModal">
			<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
				<GlobSpinner :isLoading="isLoadingShowReportModal"/>
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobRow :showBorderBottom="true">
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.asset')}}
								<h4>{{selectedOeeTimeLog.AssetName}}</h4>
							</label>
						</GlobCol>
					</GlobRow>
					<GlobRow :showBorderBottom="true" class="mas-mt-4">
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.started')}}
								<h4>{{selectedOeeTimeLog.StartDateTime}}</h4>
							</label>
						</GlobCol>
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.stopped')}}
								<h4>{{selectedOeeTimeLog.StopDateTime}}</h4>
							</label>
						</GlobCol>
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.run_time')}}
								<h4>{{selectedOeeTimeLog.RunTime ? selectedOeeTimeLog.RunTime : 0 }} {{$t('maintenance_plan.oee.minutes')}}</h4>
							</label>
						</GlobCol>
						<GlobCol :medium="4">
								<label>
									{{ $t('maintenance_plan.oee.fields.time_stopped') }}
									<h4>{{ selectedOeeTimeLog.TimeStopped ? selectedOeeTimeLog.TimeStopped : 0 }} {{ $t('maintenance_plan.oee.minutes') }}</h4>
								</label>
							</GlobCol>
					</GlobRow>
					
					<!-- If not reported from IPS the show all fields as active -> Start -->
					<template v-if="!reportStopReasonModel.ReportedByAccountId">
						<GlobRow class="mas-mt-4">
							<GlobCol :medium="4">
								<label>
									{{ $t('maintenance_plan.oee.fields.reason') }}*
									<InputDynamic
									:fullWidth="true"
									:type="'virtualselect'"
									:placeholder="$t('maintenance_plan.oee.fields.reason')"
									v-model="reportStopReasonModel.OeeReasonId"
									:data="reasons"
									/>
								</label>
							</GlobCol>
						</GlobRow>
						<GlobRow>
							<GlobCol :medium="12">
								<label>
									{{ $t('maintenance_plan.oee.fields.description_or_comments') }}
									<InputDynamic
									:fullWidth="true"
									:type="'textarea'"
									:placeholder="$t('maintenance_plan.oee.fields.description_or_comments')"
									v-model="reportStopReasonModel.Description"
									:rows="6"
									/>
								</label>
							</GlobCol>
						</GlobRow>
					</template>
					<!-- If not reported from IPS the show all fields as active -> End	-->

					<!-- If reported from IPS but not added solution -> Start -->
					<template v-else>
						<GlobRow class="mas-mt-4" :showBorderBottom="true">
								<GlobCol :medium="4">
									<label>
										{{ $t('maintenance_plan.oee.fields.reason') }}
										<h4>{{ reportStopReasonModel.OeeReason }}</h4>
									</label>
								</GlobCol>
							</GlobRow>
							<GlobRow class="mas-mt-4" :showBorderBottom="true" v-if="reportStopReasonModel.Description">
								<GlobCol :medium="12">
									<label>
										{{ $t('maintenance_plan.oee.fields.description_or_comments') }}
										<h4>{{ reportStopReasonModel.Description}}</h4>
									</label>
							</GlobCol>
						</GlobRow>
					</template>
					<!-- If reported from IPS but not added solution -> End -->
					
					<GlobRow :class="reportStopReasonModel.ReportedByAccountId ?' mas-mt-4' : ''">
						<GlobCol :medium="12">
							<label>
								{{ $t('maintenance_plan.oee.fields.solution') }}*
								<InputDynamic
								:fullWidth="true"
								:type="'textarea'"
								:placeholder="$t('maintenance_plan.oee.fields.solution')"
								v-model="reportStopReasonModel.Solution"
								:rows="6"
								/>
							</label>
						</GlobCol>
					</GlobRow>


					
					<button class="button button--margin button--small mas-mb-4 mas-mt-3" :disabled="isSaveStopReasonButtonDisabled" @click="saveStopReason()">{{$t('general.button.save')}}</button>
				</GlobXpanel>
			</GlobXpanel>
		</GlobModal>

		<!-- Report summary modal -->
		<GlobModal :widthInPx="800" class="mas-oee-report" :title="$t('maintenance_plan.oee.stop_reason_summary')" @close="showReportSummaryModal = false" :show="showReportSummaryModal" v-if="showReportSummaryModal">
			<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobRow :showBorderBottom="true">
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.asset')}}
								<h4>{{selectedOeeTimeLog.AssetName}}</h4>
							</label>
						</GlobCol>
					</GlobRow>
					<GlobRow :showBorderBottom="true" class="mas-mt-4">
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.started')}}
								<h4>{{selectedOeeTimeLog.StartDateTime}}</h4>
							</label>
						</GlobCol>
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.stopped')}}
								<h4>{{selectedOeeTimeLog.StopDateTime}}</h4>
							</label>
						</GlobCol>
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.run_time')}}
								<h4>{{selectedOeeTimeLog.RunTime ? selectedOeeTimeLog.RunTime  : 0}} {{$t('maintenance_plan.oee.minutes')}}</h4>
							</label>
						</GlobCol>
					</GlobRow>
					<GlobRow class="mas-mt-4">
						<GlobCol :medium="4">
							<label>
								{{ $t('maintenance_plan.oee.fields.time_stopped') }}
								<h4>{{ selectedOeeTimeLog.TimeStopped ? selectedOeeTimeLog.TimeStopped : 0 }} {{ $t('maintenance_plan.oee.minutes') }}</h4>
							</label>
						</GlobCol>
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.reason')}}
								<h4>{{selectedOeeTimeLog.Reason}}</h4>
							</label>
						</GlobCol>
						<GlobCol :medium="4">
							<label>
								{{$t('maintenance_plan.oee.fields.reported_by')}}
								<h4>{{selectedOeeTimeLog.ReportedBy}}</h4>
							</label>
						</GlobCol>
						<GlobCol :medium="12" v-if="selectedOeeTimeLog.Description">
							<label>
								{{$t('maintenance_plan.oee.fields.description_or_comments')}}
								<h4>{{selectedOeeTimeLog.Description}}</h4>
							</label>
						</GlobCol>
						<GlobCol :medium="12">
								<label>
									{{ $t('maintenance_plan.oee.fields.solution') }}
									<h4>{{ selectedOeeTimeLog.Solution }}</h4>
								</label>
							</GlobCol>
					</GlobRow>
				</GlobXpanel>
			</GlobXpanel>
		</GlobModal>

		<!-- Show approve service modal -->
		<GlobModal :widthInPx="800" 
				:title="selectedOeeLog && selectedOeeLog.IsApproved ? $t('maintenance_plan.oee.view_report') : $t('maintenance_plan.oee.approve_report')" @close="showApproveServiceModal = false" 
			:show="showApproveServiceModal && !hideApproveModal" v-if="showApproveServiceModal">
			<ApproveService 
				:oeeTimeLogId="selectedOeeLog.Id"
				@close="serviceApproved()"
				@hideApproveModal="(val) => hideApproveModal = val"></ApproveService>
		</GlobModal>
		
</div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import moment from 'moment';
import ApproveService from './ApproveService.vue';
import { convertUtcToLocalTime } from '@/helpers.js';

export default {
components: {
	ApproveService
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const notifications = useNotifications();
	const router = useRouter();
	const endpoint = computed(() => api.oee);
	const filterURL =  computed(() => api.filterURLs.oee);
	const filters = ref({});

	//Report stop reason modal
	const selectedOeeTimeLog = ref(null);
	const isLoadingShowReportModal = ref(false);
	const showReportModal = ref(false)
	const showReportSummaryModal = ref(false)
	const reportStopReasonModel = ref({});
	const reasons = ref([]);

	//Approval
	const selectedOeeLog = ref(null);
	const showApproveServiceModal = ref(false);
	const hideApproveModal = ref(false);

	const saveStopReason = () => {
		isLoadingShowReportModal.value = true;
		endpoint.value.saveReport(selectedOeeTimeLog.value.Id, reportStopReasonModel.value).then((res) => {
			notifications.push(
					{
						msg: tm('maintenance_plan')['oee']['save_report_success']
					},
					"success",
					false
			);
			isLoadingShowReportModal.value = false;
			showReportModal.value = false;
			selectedOeeTimeLog.value = false;
			loadItems();
		})
	}
    
	const isLoading = ref(false);
	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "AssetName",
				Value: computed(() => tm('maintenance_plan')['oee']['fields']['asset'])
			},
			{
				Alias: "StartDateTime",
				Value: computed(() => tm('maintenance_plan')['oee']['fields']['started'])
			},
			{
				Alias: "StopDateTime",
				Value: computed(() => tm('maintenance_plan')['oee']['fields']['stopped'])
			},
            {
				Alias: "RunTime",
				Value: computed(() => tm('maintenance_plan')['oee']['fields']['run_time'])
			},
			{
				Alias: "TimeStopped",
				Value: computed(() => tm('maintenance_plan')['oee']['fields']['time_stopped'])
			},
			{
				Alias: "Reason",
				Value: computed(() => tm('maintenance_plan')['oee']['fields']['reason'])
			},
            {
				Alias: "ReportedBy",
				Value: computed(() => tm('maintenance_plan')['oee']['fields']['reported_by'])
			}

	]);

	const actions = computed(()=> { return [
		{
			icon: 'fas fa-pen',
			description: tm('maintenance_plan')['oee']['report_stop_reason'],
			if: (obj) => {
				return !obj.ReportedByAccountId ? true : false;
			},
			action: (obj) => {
				reportStopReasonModel.value = {};
				if(obj.StartDateTime){
					obj.StartDateTime = convertUtcToLocalTime(obj.StartDateTime, 'YYYY-MM-DD HH:mm');
				}

				if(obj.StopDateTime){
					obj.StopDateTime = convertUtcToLocalTime(obj.StopDateTime, 'YYYY-MM-DD HH:mm');
				}
				openReportModal(obj);
			}
		},
		{
			icon: 'fas fa-file-alt',
			description: tm('maintenance_plan')['oee']['view_report'],
			if: (obj) => {
				return obj.ReportedByAccountId && obj.IsApproved ? true : false;
			},
			color: "#01188d",
				action: (obj) => {
					selectedOeeLog.value = obj;
					showApproveServiceModal.value = true;
					
				}
		},
		{
			icon: 'far fa-eye',
			if: (obj) => {
				return !obj.IsApproved && obj.ReportedByAccountId ? true : false;
			},
					description: tm('maintenance_plan')['oee']['approve'],
					action: (obj) => {
						selectedOeeLog.value = obj;
						showApproveServiceModal.value = true;
		
					}
		},
	]});

	const openReportModal = (obj) => {

		selectedOeeTimeLog.value = obj;
		showReportModal.value = true;	

		isLoadingShowReportModal.value = true;

		api.assets.getOeeReasons(obj.AssetId).then((res) => {
			reasons.value = res.data.map((item) => {
			   return {
				   name: item.Name,
				   value: item.Id
			   }
			})
			reportStopReasonModel.value.ReportedByAccountId = obj.ReportedByAccountId;
			reportStopReasonModel.value.OeeReasonId = obj.OeeReasonId;
			reportStopReasonModel.value.OeeReason = obj.Reason;
			reportStopReasonModel.value.Description = obj.Description;

		   isLoadingShowReportModal.value = false;
		})
		
	}

	 const isSaveStopReasonButtonDisabled = computed(() => {
		// if(!reportStopReasonModel.value.Solution || !reportStopReasonModel.value.OeeReasonId){
		// 	return true;
		// }
		return false;
	})


	const renderFunctions = computed(()=> {
		return {
			RunTime(obj){
				if(obj.RunTime){
					return obj.RunTime+' '+tm('maintenance_plan')['oee']['minutes'];
				}else{
					return '0 '+tm('maintenance_plan')['oee']['minutes'];
				}
				
			},
			TimeStopped(obj) {
				if (obj.TimeStopped) {
					return obj.TimeStopped + ' ' + tm('maintenance_plan')['oee']['minutes'];
				} else {
					return '0 ' + tm('maintenance_plan')['oee']['minutes'];
				}
			},
			StartDateTime(obj){
				if(!obj.StartDateTime){
					return '';
				}
				return convertUtcToLocalTime(obj.StartDateTime, 'YYYY-MM-DD HH:mm')
			},
			StopDateTime(obj){
				if(!obj.StopDateTime){
					return '';
				}
				return convertUtcToLocalTime(obj.StopDateTime, 'YYYY-MM-DD HH:mm')
			}
		}
	});
	
	 onMounted(async() => {
          loadItems();
	 });

	const loadItems = async(e) => {
		isLoading.value = true;
		if(e){
			if(e.isReset){
				filters.value = {filters: {}};
			}else{
				filters.value = e;
			}
			
		}

		endpoint.value.get({...filters.value}).then(function(res){
		   mainList.value =  res.data;
		   isLoading.value = false;
		   reasons.value = res.reasons.map((item) => {
			   return {
				   name: item.Name,
				   value: item.Id
			   }
		   })
		})
	}

	const serviceApproved = () => {
		loadItems();
		showApproveServiceModal.value = false
	}

    return {
		loadItems,
		endpoint,
		mainList,
		mainListHead,
		actions,
		renderFunctions,
		isLoading,
		filterURL,
		//Report stop reason
		selectedOeeTimeLog,
		showReportModal,
		isLoadingShowReportModal,
		reportStopReasonModel,
		reasons,
		saveStopReason,
		showReportSummaryModal,
		isSaveStopReasonButtonDisabled,
		//Approval
		selectedOeeLog,
		showApproveServiceModal,
		hideApproveModal,
		serviceApproved
    };
  }
};
</script>

<style lang="scss" scoped>
	h4{
		margin-top: 7px !important;
		margin-bottom: 7px !important;
	}
</style>