<template>
	<div ref="globVirtualSearch" class="ips-custom-dropdown-virtual-search dropdown-search" >
		<input
		:disabled="checkDisable()"
		type="text" class="mas-input"
		@focus="focus"
		@blur="blur"
		v-model="inputString"
		ref="input"
		:placeholder="placeholder ? placeholder : 'Sök'"
		v-on:keyup.enter="onEnter"
		/>

		<a v-if="inputString  && !hideInput && !disabled" @click="clear" class="clear">&times;</a>
		<div v-if="showDropDown && isSearchDone && data.length > 0"  class="dropdown-search__dropdown" :style="{ minWidth : dropdownMinWidth }">
			<RecycleScroller class="scroller dropdown-list"
			  :items="filterData"
			  :item-size="itemSize"
				key-field="value">
		  <template v-slot="{ item }">
				<li class="dropdown-list__item" @click="setValue(item)" >{{item.name}}</li>
		  </template>
		</RecycleScroller>
		</div>
	</div>
</template>

<script>
export default {
	props: ['placeholder', 'data', 'value', 'hideInput', 'activeIfEmptyData', 'inputSearchString', 'makeFocus', 'enableAddTag', 'disabled'],
	data() {
		return {
			searching: false,
			inputString: this.inputSearchString,
			itemSize: 34,
			heightLimit: 250,
			dropdownMinWidth: false,
			isSearchDone: true,
		}
	},
	methods: {
		setValue(item) {
			this.$emit('input', item.value)
			this.$emit('selectedItem', item)
			this.$emit('update:modelValue', item.value)
			this.searching = false;
			if(!this.hideInput){
			   this.inputString = item.name
			}
		},
		onEnter(){
			if(this.enableAddTag){
				this.$emit('addTag', this.inputString);
				this.inputString = '';
				this.$refs.input.blur();
			}
		},
		getHeight() {
			const itemSizeHeight = this.formattedData.length * this.itemSize;
			return itemSizeHeight <= this.heightLimit ? '' : `height: ${this.heightLimit}px;`;
		},
		clear() {
			this.$emit('input', '')
			this.$emit('clear')
			this.$emit('searchKey', '')
			this.inputString = ''
		},
		focus() {
			this.searching = true;
		},
		focusInput() {
			this.$refs.input.focus();
		},
		blur(e) {
			setTimeout(() => {
				this.searching = false;
			}, 400);
		},
		checkDisable(){
			if(this.disabled){
				return true;
			}
			return this.data.length <= 0 && !this.activeIfEmptyData ? true : false;
		},
		updateDropDownWidth(){
			if(this.$refs['globVirtualSearch']){
				let parentDropDownSelectWidth = this.$refs['globVirtualSearch'].clientWidth;
				if(this.formattedData && this.formattedData.length){
					let bigStringInData = this.formattedData.reduce((a, b)=> a.name.length > b.name.length ? a : b);
					const dropDownWidth = (bigStringInData.name.length * 7) + 70;
					if(dropDownWidth >= parentDropDownSelectWidth){
						const get150PercentOfDropDownWidth = ((parentDropDownSelectWidth/100)*75) + parentDropDownSelectWidth;
						if(dropDownWidth > get150PercentOfDropDownWidth){
							this.dropdownMinWidth = parentDropDownSelectWidth + (parentDropDownSelectWidth/100)*75;
						} else {
							this.dropdownMinWidth = dropDownWidth;
						}
						this.dropdownMinWidth = this.dropdownMinWidth+'px';
					} else {
						 this.dropdownMinWidth = '100%';
					}
				}
			}
		},
		setSelectedItem(val){
			val = val && val.target ? val.target.value : val;
			const found = this.formattedData.find(i => i.value == val)
			this.inputString = found && found.name ? found.name : val
		}
	},
	computed: {
		showDropDown() {
			return this.searching;
		},
		formattedData() {
			return typeof this.data[0] == 'object' ? this.data.filter(i=> i.name != null) : this.data.map(i => {
				return {value: i, name: i}
			})
		},
		filterData() {
			if(this.inputString){
				const pattern = new RegExp(this.inputString, 'gi');
				return this.formattedData.filter(i => i.name.match(pattern)) || ['Inga resultat']
			} else {
				return this.formattedData
			}
		}
	},
	mounted(){
		if(this.value){
			this.setSelectedItem(this.value)
		}
		if(this.makeFocus){
			this.focusInput();
		}
		this.updateDropDownWidth();
	},
	watch: {
		value(val) {
			this.setSelectedItem(val)
		},
		inputString(){
			this.$emit('searchKey', this.inputString)
			this.isSearchDone = false;
			setTimeout(() => this.isSearchDone = true, .1);
		},
		data() {
			if(this.value){
				this.setSelectedItem(this.value)
			}
		}
	}
}
</script>

<style lang="scss" >
	.ips-custom-dropdown-virtual-search{
		&.dropdown-search {
			position: relative;

			.clear {
				position: absolute;
				right: .8rem;
				top: 35%;
				line-height: 0;
				font-weight: 500;
				font-size: 2rem;
				cursor: pointer;
				text-decoration: none;
			}
			input {
				width: 100%;
				border: 1px solid #C6DFE1;
				padding-right: 2rem;
				&:disabled {
					opacity: .5;
					background-color: #E6E9ED;
				}
			}
			.dropdown-search__dropdown {
				position: absolute;
				background-color: white;
				border-radius: 5px;
				border: 1px solid #E6E9ED;
				z-index: 20;
				top: 68%;
				overflow-y: auto;
				width: 100%;

			}
		}
		.dropdown-list {
			list-style-type: none;
			padding: 0;
			margin-bottom: 0;
			.dropdown-list__item {
				padding: .5rem 1.5rem;
				cursor: pointer;
				border-bottom: 1px solid #E6E9ED;
				&:last-child {
					border-bottom: none;
				}
				&:hover {
					position: relative;
					background-color: #E6E9ED;
					z-index: 21;
				}
			}
		}
		&.dropdown-search {
			.dropdown-search__dropdown {
				width: unset;
				min-width: 100%;
				max-width: 150%;
				max-height: 250px;
			}
		}
		.vue-recycle-scroller.ready .vue-recycle-scroller__item-view {
			position: relative;
			transform: none !important;
			li{
				word-wrap: break-word;
			}
		}
	}
	.vue-recycle-scroller.ready{
		margin: 0 !important;
	}
	.vue-recycle-scroller__item-wrapper{
		margin: 0 !important;
	}
	.vue-recycle-scroller__item-view{
		margin-top: 0 !important;
	}
</style>