<template>
	<div class="ips-xpanel" 
			:class="`${expanded ? 'ips-xpanel--expanded' : ''} ${noShadow ? 'ips-xpanel--noshadow' : ''} ${isCRUD ? 'ips-xpanel--crud' : ''}`"
			:style="minHeight ? 'min-height:'+minHeight : ''"
			>
		<div class="ips-xpanel__title" :margin="false" v-if="title">
			<div class="ips-xpanel__title-left">
				<p v-html="title" class="ips-xpanel__title-text" :class="bigTitle ? 'ips-xpanel__title-text--big' : ''"></p>
				<p style="margin-top: 0;" v-html="subTitle" v-if="subTitle"></p>
			</div>
			<div class="ips-xpanel__title-right">
				<ul v-if="navbar && navbar.active" class="ips-xpanel__toolbox">
					<li v-if="navbar.actions" style="position: relative;right: 0;">
						<button class="ips-xpanel__toolbox-toggle" @click="toggleDropdown"><i class="fas fa-wrench"></i></button>
						<div class="ips-xpanel__toolbox-dropdown" v-if="isDropdownVisible" @mouseleave="isDropdownVisible = false">
							<a class="ips-xpanel__toolbox-dropdown-item" v-for="action in navbar.actions" :key="action.value" @click="execAction(action.value)">{{action.name}}</a>
						</div>
					</li>
				</ul>
				<ul v-if="tools" class="ips-xpanel__toolbox">
					<li><a @click="show = !show" class="collapse-link"><i :class="`fa fa-chevron-${show ? 'up' : 'down'}`"></i></a></li>
					<li><a @click="fullscreen"><i :class="`fas fa-${!expanded ? 'expand' : 'compress'}`"></i></a></li>
				</ul>
				<ul v-if="actions" class="ips-xpanel__toolbox">
					<li v-for="action in actions" :key="action.name"><a @click="doAction(action.function)"><i :class="`fas fa-${action.icon}`"></i></a></li>
				</ul>
			</div>
			<div class="title-buttons">
				<slot name="buttons"></slot>
			</div>
		</div>
		<div v-show="show" class="ips-xpanel__content" >
			<slot/>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
export default {
	props: {
		title: {
			type: String,
			required: false
		},
		subTitle: {
			type: String,
			required: false
		},
		navbar: {
			type: Object,
			required: false
		},
		bigTitle: {
			type: Boolean,
			required: false,
			default: false
		},
		tools: {
			type: Boolean,
			required: false,
			default: true
		},
		actions: {
			type: Array,
			required: false
		},
		noShadow: {
			type: Boolean,
			required: false
		},
		avoidHR: {
			type: Boolean,
			required: false
		},
		isCRUD: {
			type: Boolean,
			required: false
		},
		minHeight: {
			type: String,
			required: false
		}
	},
	setup(props, { emit }) {
		const show = ref(true);
		const doAction = (action) => {
			if (typeof action == 'function') {
				action();
			}
		}

		const execAction = (action) => {
			props.navbar.functions[action]();
		}

		const expanded = ref(false);
		const fullscreen = () => {
			expanded.value = !expanded.value;
			emit('expand', expanded.value)
		}

		const isDropdownVisible = ref(false);
		const toggleDropdown = () => {
			isDropdownVisible.value = !isDropdownVisible.value
		}

		return {
			doAction,
			expanded,
			fullscreen,
			show,
			isDropdownVisible,
			toggleDropdown,
			...props
		}
	}
}
</script>

<style lang="scss">
	.ips-xpanel {
		background: #FFFFFF;
		box-shadow: 0px 10px 25px rgba(0, 48, 75, 0.03);
		border-radius: 15px;
		@include hover-elevation(1);
		&--expanded {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			z-index: 12;
			overflow-y: auto;
		}
		&--noshadow {
			box-shadow: none !important;
			background-color: unset !important;

			.ips-xpanel__content{
				background-color: unset !important;
			}
		}
		&__toolbox {
			display: flex;
			justify-content: flex-end;
			padding-top: 9px;
		}
		&__title {
			background: linear-gradient(90.1deg, $ma-darkBlue 10.9%, $ma-cornflowerBlue 90.69%);
			border-radius: 10px 10px 0px 0px;
			padding: 4px 4px;
			@include xy-grid();
			&-text {
				font-size: 18px;
				margin: 0;
				&--big {
					font-size: rem-calc(22)
				}
				font-family: 'Inter';
				font-style: normal;
				font-weight: 500;
				line-height: 40px;
				color: $ma-white;
				margin-left: 1rem;
			}
			&-right {
				@include xy-cell(1 of 2, true, 0);
				display: flex;
				justify-content: flex-end;
			}
			&-left {
				@include xy-cell(1 of 2, true, 0)
			}
		}
		&__toolbox {
			list-style: none;
			margin: 0;
			li {
				margin: 0 .5rem;
				position: relative;
				cursor: pointer;
				a, button {
					color: $ma-white;
					&:focus {
						outline: 0;
					}
				}
				i{
					font-size: 17px;
				}
			}
			&-toggle {

			}
			&-dropdown {
				position: absolute;
				background-color: white;
				@include elevation(1);
				padding: .5rem;
				border-radius: 4px;
				right: 0;
				&-item {
					cursor: pointer;
					white-space: nowrap;
					font-size: .9rem;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
		&__content {
			@include scrollbar(4px);
			padding-left: 1rem;
    		padding-right: 1rem;
			padding-bottom: 18px;
			label{
				font-size: 15px !important;
				color: $mas-carbonGrey;
				font-weight: 500;
				font-family: 'Inter';
				div{
					margin-top: 5px;
				}
			}
		}

		&--crud {
			border-radius: 0%;
			padding: 2rem !important;
			background-color: $ma-white !important;

			.ips-xpanel__content {
				padding: 0;
			}

			.ips-xpanel__title-text{
				margin-left: 0rem;
			}
		}

		.title-buttons{
			position: absolute;
    		right: 3%;
		}

	}
	.section-heading{
		font-size: 20px !important;
		font-weight: 600 !important;
		margin: 0;
		margin-bottom: 28px !important;
		color: $ma-cornflowerBlue !important;
		border-bottom: 1px solid $ma-lightGray;
    	padding-bottom: 11px;
	}
</style>