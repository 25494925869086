<template>
    <GlobXpanel :title="$t('reports.weekly.machine_stop_time')">
      <div id="machineStackedChartdiv"></div>
    </GlobXpanel>
  </template>
  
  <script setup>
  import { ref, watch, onMounted, onBeforeUnmount } from "vue";
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";
  import moment from "moment";
  
  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  });
  
  let chart = null;

  watch(() => props.data, (newVal) => {
    plotChart();
  }, { deep: true });
  
  const plotChart = () => {
    if (chart) {
      chart.dispose(); // Destroy previous chart instance before recreating
    }
  
    // Create a new chart instance
    chart = am4core.create("machineStackedChartdiv", am4charts.XYChart);
    
    // Group data by date for stacked chart format
    let formattedData = {};
    let addedDates = [];
    props.data.forEach(entry => {
      let dateKey = entry.StartDate;
      if (!addedDates.includes(dateKey)) {
        formattedData[dateKey] = { date: moment(entry.StartDate).format("MMM DD") }; // Store date
        addedDates.push(dateKey);
      }
      formattedData[dateKey][entry.Machine] = entry.MinutesStopped; // Store machine's stop time
    });
  
    chart.data = Object.values(formattedData); // Convert object to array
  
    // Configure X-axis (Date as Category)
    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = "date"; // Use string StartDate
    dateAxis.renderer.labels.template.rotation = 90;
    dateAxis.renderer.labels.template.verticalCenter = "middle";
    dateAxis.title.text = "Date";
    dateAxis.renderer.minGridDistance = 50;
  
    // Configure Y-axis (Minutes Stopped)
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Minutes Stopped";
  
    // Get unique machines for stacked series
    const machines = [...new Set(props.data.map(d => d.Machine))];
  
    machines.forEach(machine => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "date";
      series.dataFields.valueY = machine; // Dynamically set machine names as fields
      series.name = machine; // Legend entry
      series.stacked = true;
      series.tooltipText = "{name}: {valueY} min";
    });
  
    // Enable tooltips & cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomX";
  
    // Add legend
    chart.legend = new am4charts.Legend();
  };
  
  // Watch for data changes and update the chart
  watch(() => props.data, (newVal) => {
    if (newVal.length) {
      plotChart();
    }
  }, { deep: true });
  
  // Initialize chart on mount
  onMounted(() => {
    plotChart();
  });
  
  // Cleanup chart on component unmount
  onBeforeUnmount(() => {
    if (chart) {
      chart.dispose();
    }
  });
  </script>
  
  <style scoped>
  #machineStackedChartdiv {
    width: 100%;
    height: 500px;
  }
  </style>
  