<template>
    <GlobXpanel 
        :actions="chartActions"
        :title="$t('reports.weekly.weekly_report')">
		<GlobSpinner :isLoading="isLoading" />
        <div id="chartdiv"></div>
    </GlobXpanel>

	<!-- Select Sensor Modal -->
	<GlobModal :widthInPx="400"
		:title="$t('reports.weekly.sensor')"
		@close="showSelectSensorModal = false"
		:show="showSelectSensorModal">
		<GlobSpinner :isLoading="isLoadingSensorModal" />
		<GlobXpanel 
			:noShadow="true" 
			:tools="false" 
			:avoidHR="true" 
			:isCRUD="true">
			<GlobRow>
				<GlobCol :medium="12" class="mas-mb-4">
					<label>
						{{ $t('reports.weekly.select_sensor') }}
						<InputDynamic
						:data="sensors"
						v-model="selectedSensorId"
						:type="'virtualselect'"
						:placeholder="$t('reports.weekly.sensor')"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="12" class="mas-mt-4">
					<button class="button"
						@click="updateChart()"
						>
						{{ $t('reports.weekly.update_chart') }}</button>
				</GlobCol>
			</GlobRow>
		</GlobXpanel>
	</GlobModal>


	 
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useApi } from "@/plugins/api";

// Define props for data
const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  sensorData: {
    type: Array,
    required: true
  },
  sensorId: {
    type: Number,
    required: false,
    default: null
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false
  }
});

const emit = defineEmits(["updateChart"]);

onMounted(() => {
  getSensorData();
});

watch(() => props.data, (newVal, oldVal) => {
  selectedSensorId.value = selectedSensorId.value || props.sensorId;
  chartDataModel.value = newVal;
  lineChartDataModel.value = props.sensorData;

  lineChartMax.value = Math.max(...props.sensorData.map((entry) => entry.value));

  plotChart(newVal, lineChartDataModel.value);
});

const { tm } = useI18n();
const api = useApi();
const endpoint = api.reports;

const chartDataModel = ref([]);
const lineChartDataModel = ref([]);
const lineChartMax = ref(1);

const showSelectSensorModal = ref(false);
const isLoadingSensorModal = ref(false);
const sensors = ref([]);
const selectedSensorId = ref(null);


let chart;

const plotChart = (data, lineData) => {
  // Create chart instance
  chart = am4core.create("chartdiv", am4charts.XYChart);

  // Convert the data into chart-compatible format
  const chartData = data.map((entry) => ({
    date: new Date(entry.time),
    asset: entry.asset,
    value: entry.value,
  }));

  // Create a dataset for LineSeries (e.g., smoothed or transformed data)
  const lineChartData = lineData.map((entry) => ({
    date: new Date(entry.time),
    asset: entry.asset,
    value: entry.value
  }));

  // Configure X-axis (Date)
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.title.text = "Date & Time";
  dateAxis.renderer.minGridDistance = 50;

  // Configure Y-axis (Status) for the main chart (On/Off values)
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = "Status";
  valueAxis.min = 0;
  valueAxis.max = 1;
  valueAxis.strictMinMax = true;

  // Replace Y-axis values with "On" for 1 and "Off" for 0
  valueAxis.renderer.labels.template.adapter.add("text", (text) => {
    return text === "1" ? "On" : text === "0" ? "Off" : "";
  });

  // Create a secondary Y-axis for the sensor data (if sensorId is selected)
  let valueAxisSensorData;
  if (selectedSensorId.value) {
    valueAxisSensorData = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisSensorData.title.text = sensors.value.find((sensor) => sensor.value === selectedSensorId.value).name;
    valueAxisSensorData.min = 0;
    valueAxisSensorData.max = lineChartMax.value;
    valueAxisSensorData.renderer.opposite = true; // Position on the opposite side
  }

  // Function to create StepLineSeries and LineSeries for each asset
  const createSeries = (assetName, stepColor, lineColor) => {
  // Filter chart data for the current asset
  const assetChartData = chartData.filter((item) => item.asset === assetName);
  const assetLineChartData = lineChartData.filter((item) => item.asset === assetName);

  // Create StepLineSeries (for discrete "On/Off" values) - this will always show
  const stepSeries = chart.series.push(new am4charts.StepLineSeries());
  stepSeries.dataFields.dateX = "date";
  stepSeries.dataFields.valueY = "value";
  stepSeries.dataFields.asset = "asset";
  stepSeries.name = `${assetName}`;
  stepSeries.stroke = am4core.color(stepColor);
  stepSeries.strokeWidth = 2;
  stepSeries.data = assetChartData;

  // Only create LineSeries for sensor data if `assetLineChartData` has entries
  if (selectedSensorId.value && valueAxisSensorData && assetLineChartData.length) {
    const lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.dateX = "date";
    lineSeries.dataFields.valueY = "value";
    lineSeries.dataFields.asset = "asset";
    lineSeries.name = `${assetName} (${selectedSensor.value ? selectedSensor.value.name : ''})`;
    lineSeries.stroke = am4core.color(lineColor);
    lineSeries.strokeWidth = 2;
    lineSeries.tensionX = 0.9; // Smooth the line

    // Assign the secondary Y-axis to the sensor data series
    lineSeries.yAxis = valueAxisSensorData;

    lineSeries.tooltipText = "Asset: {asset}\nDate: {dateX.formatDate('yyyy-MM-dd HH:mm')}\nValue: [bold]{value}[/]";
    lineSeries.tooltip.pointerOrientation = "horizontal";
    lineSeries.data = assetLineChartData;
  } else {
    // If there's no line chart data, remove the legend entry for this asset
    stepSeries.name = `${assetName}`;
  }
};


  // Add StepLineSeries and LineSeries for each asset
  const uniqueAssets = [...new Set(chartData.map((entry) => entry.asset))];
  uniqueAssets.forEach((assetName, index) => {
    createSeries(assetName, getRandomColor(index), getRandomColor(index + 1));
  });

  // Add legend
  chart.legend = new am4charts.Legend();

  // Enable chart cursor
  chart.cursor = new am4charts.XYCursor();
};



// Function to generate random color
const getRandomColor = (index) => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const chartActions = [
  {
    icon: 'cog',
    function: () => {
        showSelectSensorModal.value = true;
    }
  }
];

const getSensorData = () => {
    endpoint.getSensors()
	.then((response) => {
		console.log(response.data);
		sensors.value = response.data.map((sensor) => ({
			value: sensor.Id,
			name: sensor.Name,
		}));
	})
	.catch((error) => {
		//
	})
	.finally(() => {
		isLoadingSensorModal.value = false;
	});
};


const updateChart = () => {

  api.account.updateAccountSettings({
    parameter: 'WeeklyReportDynamicChartSensorId',
    value: selectedSensorId.value
  })

	emit('updateChart', {
		sensorId: selectedSensorId.value
	});

	showSelectSensorModal.value = false;
	isLoadingSensorModal.value = false;
};

const selectedSensor = computed(() => {

  if(!sensors.value.length) {
    return {
      value: 0,
      name: ''
    }
  }

	return sensors.value.find((sensor) => sensor.value === selectedSensorId.value);
});
</script>

<style lang="scss" scoped>
  #chartdiv {
	width: 100%;
	height: 500px;
  }
</style>