<template>
    <GlobXpanel 
        :title="$t('reports.weekly.note.notes')">
		<GlobSpinner :isLoading="isLoadingNotes" />

        <GlobTable
            :maxHeight="mainTableMaxHeight ? mainTableMaxHeight : 30"
            :data="{
                head : notesTableHead,
                body : notesTableBody
            }"
            :itemRenderFunctions="renderFunctions"
            :actions="actions"
        />
    </GlobXpanel>

    <NoteCRUD
        v-if="selectedItemToEdit && selectedItemToEdit.Type == 'Note'"
        @close="closeModal"
        :id="selectedItemToEdit.Id">
    </NoteCRUD>

    <TroubleShootingGuideCRUD
        v-if="selectedItemToEdit && selectedItemToEdit.Type == 'TroubleshootingGuide'"
        @close="closeModal"
        :id="selectedItemToEdit.Id">
    </TroubleShootingGuideCRUD>

</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { convertUtcToLocalTime } from '@/helpers.js';

import NoteCRUD from './Note.vue';
import TroubleShootingGuideCRUD from './TroubleShootingGuide.vue';

const props = defineProps({
    data: {
        type: Object,
        required: true
    },
    isLoading: {
        type: Boolean,
        required: false,
        default: false
    },
    mainTableMaxHeight: {
        type: Number,
        required: false,
        default: 30
    }
});

const emits = defineEmits(['update']);

const { tm } = useI18n();
const isLoadingNotes = ref(false);
const notesTableHead = ref([
    {
        Alias: "CreatedAt",
        Value: computed(() => tm('reports')['weekly']['note']['created_at'])
    },
    {
        Alias: "Heading",
        Value: computed(() => tm('reports')['weekly']['note']['heading'])
    },
    {
        Alias: "CreatedBy",
        Value: computed(() => tm('reports')['weekly']['note']['created_by'])
    },
    {
        Alias: "Type",
        Value: computed(() => tm('reports')['weekly']['note']['type'])
    }

]);

const notesTableBody = ref([]);
const selectedItemToEdit = ref(null);

watch(() => props.data, (newVal) => {
    notesTableBody.value = newVal;
});

watch(() => props.isLoading, (newVal) => {
    isLoadingNotes.value = newVal;
});

const actions = computed(() => { return [
		{
			icon: 'fas fa-pen',
            action: (obj) => {
                selectedItemToEdit.value = obj;
                console.log(selectedItemToEdit.value);
            }
		}
	];
});

const renderFunctions = computed(()=> {
    return {
        CreatedAt(obj){
            return convertUtcToLocalTime(obj.CreatedAt, 'YYYY-MM-DD HH:mm')
        },
        Heading(obj){
            let content = null;

            if(obj.Type == 'Note')
            {
                content = obj.Heading ?? obj.NoteText;
            }else{
                content = obj.Heading ?? obj.Description;
            }

            if(!content) return '';

            return content.length > 50 ? content.substring(0, 50) + '...' : content;
        },
        CreatedBy(obj){
            return obj.Name+' '+obj.LastName;
        }
        
    }
});

const closeModal = (refreshList = false) => {
    selectedItemToEdit.value = null;
    if(refreshList){
        emits('update');
    }
}
</script>