<template>
    <div>
    <GlobSpinner :isLoading="isLoading" />
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true" class="mas-service-log-view">
            <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
                <!-- Approve repport section -->
                <GlobRow>
                    <template v-if="approveDetails.Id">
                        <GlobCol :medium="12" class="service-log-respoisble-persons ">
                            <p class="section-head mas-m-0">
                                {{ $t('maintenance_plan.service_log_menu.approve_service.approved_by') }}
                            </p>
                        </GlobCol>
                        <GlobCol :medium="3" class="service-log-respoisble-persons ">
                            <label>
                                {{ $t('maintenance_plan.service_log_menu.approve_service.last_updated_by') }}
                                <h4>{{ approveDetails.LastUpdatedBy }}</h4>
                            </label>
                        </GlobCol>
                        <GlobCol :medium="6" class="service-log-respoisble-persons ">
                            <label>
                                    {{ $t('maintenance_plan.service_log_menu.approve_service.last_updated_at') }}
                                    <h4>{{ approveDetails.LastUpdatedAt }}</h4>
                            </label>
                        </GlobCol>
                    </template>
                    <template v-if="approveDetails.Status != 'approved'">
                        <GlobCol :medium="7">
                            <label>{{ $t('maintenance_plan.service_log_menu.approve_service.describe_action_taken') }}*</label>
                            <InputDynamic
                                :fullWidth="true"
                                styling="width: 100%; padding: .5rem;"
                                :type="'textarea'"
                                :rows="7"
                                v-model="approveDetails.Description"
                            />
                        </GlobCol>
                        <GlobCol :medium="5" v-if="!isLoading">
                            <label>
                                    <InputToggle :label="$t('maintenance_plan.service_log_menu.approve_service.troubleshooting_guide_created')" 
                                            v-model="approveDetails.TroubleshootingGuideCreated" />
                                </label>
                        </GlobCol>
                        <GlobCol :medium="12" class="mas-mb-4">
                            <button class="button" @click="showConfimApproveModal(true)">{{ $t('maintenance_plan.service_log_menu.approve_service.approve_report') }}</button>
                            <button class="button button--orange" @click="showPendingApproveModal(true)">{{ $t('maintenance_plan.service_log_menu.approve_service.leave_pending') }}</button>
                        </GlobCol>
                    </template>
                     <template v-else>
                         <GlobCol :medium="12" class="service-log-respoisble-persons">
                            <label>
                                {{ $t('maintenance_plan.service_log_menu.approve_service.describe_action_taken') }}
                            <div class="description">
                                <h4>{{ approveDetails.Description }}</h4>
                            </div>
                            </label>
                        </GlobCol>
                        <GlobCol :medium="12" class="service-log-respoisble-persons">
                                <label>
                                    {{ $t('maintenance_plan.service_log_menu.approve_service.troubleshooting_guide_created') }}
                                <h4>
                                    {{ approveDetails.TroubleshootingGuideCreated ? $t('general.yes') : $t('general.no') }}
                                </h4>
                                </label>
                            </GlobCol>
                    </template>
                </GlobRow>
                <hr>
                <!-- Show service description -->
                <GlobRow>
                    <GlobCol :medium="12" v-if="userDescription">
                        <p class="section-head input-text">
                            {{$t('maintenance_plan.service_log_menu.view.user_description')}}
                        </p>
                        <div class="description">
                            {{userDescription}}
                        </div>
                    </GlobCol>
                </GlobRow>    
                <hr>
                <GlobRow v-if="maintenancePlan">
                    <GlobCol :medium="12" class="mas-mt-4">
                        <p class="section-head">
                            {{ $t('maintenance_plan.service_log_menu.view.maintenance_plan') }}
                        </p>
                        <GlobRow class="service-log-respoisble-persons">	
                            <GlobCol :medium="3">
                                <label>
                                    {{ $t('maintenance_plan.service_log_menu.view.maintenance_plan_name') }}
                                    <h4>{{ maintenancePlan.Name }}</h4>
                                </label>
                            </GlobCol>
                            <GlobCol :medium="9">
                                <label>
                                    {{ $t('maintenance_plan.service_log_menu.view.maintenance_plan_description') }}
                                    <h4>{{ maintenancePlan.Description }}</h4>
                                </label>
                            </GlobCol>
                        </GlobRow>
                    </GlobCol>
                </GlobRow>
                <hr>
                <!-- Show reported details -->
                <GlobRow v-if="serviceLog">
                    <GlobCol :medium="12" class="mas-mt-4" v-if="serviceLog.maintenance_plan">
                        <p class="section-head">
                            {{ $t('maintenance_plan.service_log_menu.view.maintenance_plan') }}
                        </p>
                        <GlobRow class="service-log-respoisble-persons">	
                            <GlobCol :medium="3">
                                <label>
                                    {{ $t('maintenance_plan.service_log_menu.view.maintenance_plan_name') }}
                                    <h4>{{ serviceLog.maintenance_plan.Name }}</h4>
                                </label>
                            </GlobCol>
                            <GlobCol :medium="9">
                                <label>
                                    {{ $t('maintenance_plan.service_log_menu.view.maintenance_plan_description') }}
                                    <h4>{{ serviceLog.maintenance_plan.Description }}</h4>
                                </label>
                            </GlobCol>
                        </GlobRow>
                    </GlobCol>

                    <GlobCol :medium="12" class="mas-mt-4" v-if="serviceLog.service_log_responsible && serviceLog.service_log_responsible.length">
                        <p class="section-head">
                            {{ $t('maintenance_plan.service_log_menu.view.executed_by') }}
                        </p>
                        <GlobRow v-for="logResponsible in serviceLog.service_log_responsible" :key="'service-log-view-responsible-' + logResponsible.Id" class="service-log-respoisble-persons">	
                            <GlobCol :medium="3">
                                <label>
                                    {{ $t('maintenance_plan.service_log_menu.view.user') }}
                                    <h4>{{ logResponsible.account.Name }}</h4>
                                </label>
                            </GlobCol>
                            <GlobCol :medium="3">
                                <label>
                                    {{ $t('maintenance_plan.service_log_menu.view.started') }}
                                    <h4>{{ moment(logResponsible.StartTime).format('YYYY-MM-DD HH:mm') }}</h4>
                                </label>
                            </GlobCol>
                            <GlobCol :medium="3">
                                <label>
                                    {{ $t('maintenance_plan.service_log_menu.view.stopped') }}
                                    <h4>{{ logResponsible.StopTime ? moment(logResponsible.StopTime).format('YYYY-MM-DD HH:mm') : '' }}</h4>
                                </label>
                            </GlobCol>
                            <GlobCol :medium="3">
                                <label>
                                    {{ $t('maintenance_plan.service_log_menu.view.total_time') }}
                                    <h4>{{ logResponsible.TotalTime ? logResponsible.TotalTime : 0 }} {{ $t('maintenance_plan.service_log_menu.view.minutes') }}</h4>
                                </label>
                            </GlobCol>
                        </GlobRow>
                    </GlobCol>
                    <GlobCol :medium="12" v-if="serviceDescription">
							<p class="section-head input-text">
								{{$t('maintenance_plan.service_log_menu.view.service_description')}}
							</p>
							<div class="description">
								{{serviceDescription}}
							</div>
                    </GlobCol>        
                    <GlobCol :medium="12">
                        <p class="section-head">
                            {{ $t('maintenance_plan.service_log_menu.view.comments') }}
                        </p>
                        <div class="description">
                            {{ serviceLog.Comment }}
                        </div>
                    </GlobCol>
                    <GlobCol :medium="12" v-if="serviceCheckList.length">
                        <p class="section-head">
                            {{ $t('maintenance_plan.service_log_menu.view.check_list') }}
                        </p>
                            <div class="check-list">
                            <div class="check-list-item" v-for="checkListItem in serviceCheckList" :key="'check-list-' + checkListItem.Id">
                                <span v-if="serviceLog.service_log_check_list && serviceLog.service_log_check_list.find(i => i.MaintenanceServiceDescriptionCheckListId == checkListItem.Id)">
                                    <i class="fas fa-check"></i>
                                </span>
                                <span class="not-completed-checklist" v-else>
                                    <i class="fas fa-times"></i>
                                </span>
                                <p>{{ checkListItem.Description }}</p>
                            </div>
                        </div>
                    
                    </GlobCol>
                    <GlobCol :medium="12" v-if="serviceLog.attachments && serviceLog.attachments.length">
                        <p class="section-head">
                            {{ $t('maintenance_plan.service_log_menu.view.uploaded_images') }}
                        </p>
                        <GlobRow class="file-library-section">
                            <GlobCol class="files-section " :medium="3" v-for="attachment in serviceLog.attachments" :key="'library-file-' + attachment.Id">
                                <div class='parent'>
                                    <p>{{ attachment.Name.length > 8 ? attachment.Name.slice(0, 8) + '...' : attachment.Name }}</p>
                                <GlobFilePreview 
                                        @click="downloadFile(attachment.URL, attachment.Name)"
                                        :width="'120px'"
                                        :height="'120px'"
                                        :file="{
                                            Name: attachment.Name,
                                            Type: attachment.Type,
                                            URL: attachment.URL
                                        }" />
                                </div>
                            </GlobCol>
                        </GlobRow>
                    
                    </GlobCol>
                    <GlobCol :medium="12" v-if="serviceLog.assetServiceReasons && serviceLog.assetServiceReasons.length">
                        <p class="section-head">
                            {{ $t('maintenance_plan.service_log_menu.view.service_reasons') }}
                        </p>
                        <GlobRow>
                            <GlobCol :medium="12">
                                <span v-for="(assetServiceReason, index) in serviceLog.assetServiceReasons" :key="'service-view-asset-reason-' + index" class="multiselect__tag">
                                    {{ assetServiceReason }}
                                </span>
                            </GlobCol>
                        </GlobRow>
                    
                    </GlobCol>
                    <GlobCol :medium="12" v-if="serviceLog.service_tag_relations && serviceLog.service_tag_relations.length">
                        <p class="section-head">
                            {{ $t('maintenance_plan.service_log_menu.view.tags') }}
                        </p>
                        <GlobRow>
                            <GlobCol :medium="12">
                                <span v-for="(tagRelation, index) in serviceLog.service_tag_relations" :key="'service-view-tag-' + index" class="multiselect__tag">
                                    {{ tagRelation.service_tag.Name }}
                                </span>
                            </GlobCol>
                        </GlobRow>
                    
                    </GlobCol>
                </GlobRow>
            </GlobXpanel>
        </GlobXpanel>
    </div>

    	<GlobConsequence :show="showApproveMaintenanceModal" 
    		@close="showConfimApproveModal(false)"  
            @confirm="updateApproval('approved')"
    		:title="$t('maintenance_plan.service_log_menu.approve_service.approve_report')" 
    		:message="$t('maintenance_plan.service_log_menu.approve_service.approve_report_confirm')"  />

        <GlobConsequence :show="showPendingMaintenanceModal" 
        		@close="showPendingApproveModal(false)"  
                @confirm="updateApproval('pending')"
        		:title="$t('maintenance_plan.service_log_menu.approve_service.leave_pending')" 
        		:message="$t('maintenance_plan.service_log_menu.approve_service.leave_pending_confirm')"  />
</template>
<script>
import { onMounted, reactive, ref} from "vue";
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useNotifications } from "@/plugins/notifications";
import bgThumb from '@/assets/icon/image-thumbnail.png';

export default {
    components: {},
    props: ['serviceLogId'],
    setup(props, { emit }) {
        const { tm } = useI18n();
        const api = useApi();
        const notifications = useNotifications();
        const endpoint = api.serviceLogs;

        let approveDetails = reactive({
            'Id': '',
            'Description': '',
            'TroubleshootingGuideCreated': ''
        });

        const showApproveMaintenanceModal = ref(false);
        const showPendingMaintenanceModal = ref(false);

        const serviceLog = ref({});
        const maintenancePlan = ref({});
        const serviceCheckList = ref([]);
        const userDescription = ref('');
        const serviceDescription = ref('');


        const isLoading = ref(false)
        onMounted(() => {
            getData();
        })

        const getData = () => {
            isLoading.value = true;
            endpoint.getApprovalDetails(props.serviceLogId).then((res) => {
                if (res.approval_details) {
                    approveDetails = Object.assign(approveDetails, res.approval_details);
                }
                maintenancePlan.value = res.maintenance_plan;
                serviceLog.value = res.service_log;
                serviceCheckList.value = res.service_check_list;
                userDescription.value = res.user_description;
                serviceDescription.value = res.service_description;
                isLoading.value = false;
            }); 
        }

        const updateApproval = (status) => {
            showConfimApproveModal(false);
            showPendingApproveModal(false)
            isLoading.value = true;
            endpoint.updateApprovalStatus(props.serviceLogId, {
                ...approveDetails,
                Status: status,
            }).then((res) => {
                notifications.push(
                    {
                        msg: tm('maintenance_plan')['service_log_menu']['approve_service']['reported_successfully']
                    },
                    "success",
                    false
                );

                emit('close');
            });
        }

        const showConfimApproveModal = (value) => {
            showApproveMaintenanceModal.value = value
            emit('hideApproveServiceModal', value)
        }

        const showPendingApproveModal = (value) => {
            showPendingMaintenanceModal.value = value
            emit('hideApproveServiceModal', value)
        }


        return {
            isLoading,
            approveDetails,
            serviceLog,
            maintenancePlan,
            serviceCheckList,
            moment,
            bgThumb,
            showApproveMaintenanceModal,
            showConfimApproveModal,
            showPendingApproveModal,
            showPendingMaintenanceModal,
            updateApproval,
            userDescription,
            serviceDescription
        }
    }
}
</script>

<style lang="scss">

</style>