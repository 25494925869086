<template>
  <GlobXpanel :title="$t('reports.weekly.machine_stop_time')">
    <div id="machineStopChartdiv"></div>
  </GlobXpanel>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const props = defineProps({
  data: {
    type: Array,
    required: true
  }
});

let chart = null;

const plotChart = () => {
  if (chart) {
    chart.dispose(); // Destroy previous chart instance before recreating
  }

  // Create a new chart instance
  chart = am4core.create("machineStopChartdiv", am4charts.XYChart);

  // Transform data for multiple machine lines
  let processedData = {};
  props.data.forEach(entry => {
    const machine = entry.Machine;
    const date = new Date(entry.StartDate);

    if (!processedData[machine]) {
      processedData[machine] = {};
    }
    processedData[machine][date] = entry.MinutesStopped;
  });

  // Convert processed data into chart-friendly format
  let chartData = [];
  let allDates = new Set();

  Object.values(processedData).forEach(machineData => {
    Object.keys(machineData).forEach(date => allDates.add(date));
  });

  allDates = Array.from(allDates).sort((a, b) => new Date(a) - new Date(b));

  allDates.forEach(date => {
    let dataPoint = { date: new Date(date) };
    Object.keys(processedData).forEach(machine => {
      dataPoint[machine] = processedData[machine][date] || 0;
    });
    chartData.push(dataPoint);
  });

  chart.data = chartData;

  // Configure X-axis (Date)
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.title.text = "Date";
  dateAxis.renderer.minGridDistance = 50;

  // Configure Y-axis (Minutes Stopped)
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = "Minutes Stopped";

  // Create a series for each machine
  Object.keys(processedData).forEach(machine => {
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = machine;
    series.name = machine;
    series.strokeWidth = 2;
    series.tensionX = 0.8; // Smooth line effect
    series.tooltipText = `{name}\nDate: {dateX.formatDate('yyyy-MM-dd')}\nMinutes Stopped: {valueY}`;
  });

  // Enable tooltips & cursor
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = "zoomX";

  // Add legend
  chart.legend = new am4charts.Legend();
};

// Watch for data changes and update the chart
watch(() => props.data, (newVal) => {
  if (newVal.length) {
    plotChart();
  }
}, { deep: true });

// Initialize chart on mount
onMounted(() => {
  plotChart();
});

// Cleanup chart on component unmount
onBeforeUnmount(() => {
  if (chart) {
    chart.dispose();
  }
});
</script>

<style scoped>
#machineStopChartdiv {
  width: 100%;
  height: 500px;
}
</style>
