<template>
  <thead class="ips-table-head">
    <tr>
			<th
        v-for="item in data.head"
				@click="sortByKey(item.Alias)"
        :key="generateId(item.Alias)"
      >
        {{ item.Value }}
				<i v-if="item.Alias == sortedBy" :class="`fas fa-arrow-${descending ? 'down' : 'up'} down`"></i>
      </th>
			<th v-if="actions"></th>
    </tr>
  </thead>
</template>

<script>
import { generateId } from "@/services/utils";
import { ref } from 'vue';
export default {
	props: ['data', 'actions'],
	emits: ['sorted'],
	setup(props, { emit }) {
		const sortedBy = ref('');
		const descending = ref(false)
		const sortByKey = (key) => {
			sortedBy.value = key;
			if (key !== sortedBy.value) {
				descending.value = false;
			} else {
				descending.value = !descending.value;
			}
			emit('sorted', {key, descending})
		}
		return {
			generateId,
			sortedBy,
			sortByKey,
			descending
		}
	}
}
</script>

<style lang="scss" scoped>
.ips-table-head {
	
  th {
	color: $ma-bluish;
    position: sticky;
    top: 0;
    padding: $cellpadding;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	padding-left: 25px;
	font-family: 'Inter';
	z-index: 2;
    background-color: $ma-white;
  }
}
</style>
